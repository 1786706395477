@import '../../../../variables.css';

.searchFieldContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.searchInput {
    flex-shrink: 0;
    width: 33.5rem;
}

.defaultHeading,
.hasSearchResultHeading,
.emptySearchHeading {
    font-size: var(--ark-20-font-size);
    line-height: var(--ark-32-line-height);
    color: var(--ark-gray-medium-color);
    font-weight: var(--ark-font-weight-bold);
}

.hasSearchResultHeading {
    color: var(--ark-gray-dark-color);
}

.emptySearchHeading {
    color: var(--ark-primary-blue-figma);
}

.relatedGamesHeading {
    margin-top: 2rem;
}

.tabContainer {
    padding-left: 3rem;
    padding-right: 3.5rem;
}

.groupedByTwoResult {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 0.875rem auto;
}

.groupedByTwoResult a:nth-child(1) {
    grid-row: 1/2;
}

.groupedByTwoResult a:nth-child(2) {
    grid-row: 3/4;
}

.notFoundWrapper {
    display: flex;
    align-items: center;
}

.notFoundWrapper img {
    max-width: 50px;
    margin-right: 10px;
}

.emptySearchHeading {
    grid-template-rows: 1fr 0rem auto;
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .tabContainer.extendMediaQuery {
        padding-top: 1rem;
        padding-left: 1.25rem;
        padding-right: 0;
    }

    .extendMediaQuery .searchFieldContainer {
        display: none;
    }

    .extendMediaQuery .defaultHeading,
    .extendMediaQuery .hasSearchResultHeading,
    .extendMediaQuery .emptySearchHeading {
        font-size: var(--ark-16-font-size) !important;
        line-height: var(--ark-22-line-height) !important;
        font-weight: var(--ark-font-weight-bold);
    }
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .tabContainer {
        padding-top: 1rem;
        padding-left: 1.25rem;
        padding-right: 0;
    }

    .defaultHeading,
    .hasSearchResultHeading,
    .emptySearchHeading {
        font-size: var(--ark-16-font-size) !important;
        line-height: var(--ark-22-line-height) !important;
        font-weight: var(--ark-font-weight-bold);
    }

    .relatedGamesHeading {
        margin-top: 1.125rem;
    }

    .searchFieldContainer {
        display: none;
    }
}

@media (max-width: var(--ark-small-desktop-figma-1024)) and (orientation: landscape) {
    .tabContainer {
        padding-left: 6.25rem;
    }
}

@media (max-width: 768px) and (orientation: landscape) {
    .tabContainer {
        padding-left: 1.25rem !important;
        left: 4.5rem !important;
        width: calc(100% - 4.5rem) !important;
        position: relative;
    }
}

@media (max-width: 576px) {
    .notFoundWrapper {
        align-items: flex-start;
    }
}
