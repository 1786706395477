@import '../../../../variables.css';

.icon {
    width: 22px;
    height: 22px;
    color: rgba(35, 31, 32, 1);
}

.mainContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
}

.contentWrapperNew {
    width: 100%;
}

.blogPostBody {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
}

@media (min-width: var(--ark-large-mobile-768)) {
    .blogPostBody {
        flex-direction: row;
        justify-content: space-between;
    }
    .mainContent {
        flex: 3;
        min-width: 0;
    }
    .sidebar {
        flex: 1;
        min-width: 0;
    }
    .textFieldWithImage img {
        max-width: 300px;
    }
}

/*------*/
.wrapper {
    min-height: 100vh;
    /* TODO: For test */
}

.breadcrumbsRow {
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
}

.container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.blogBody{
    padding: 0 180px;
    padding-top: 54px;
}

.contentWrapper a {
    color: var(--ark-main-blue-figma);
    font-size: var(--ark-18-font-size);
    line-height: var(--ark-24-line-height);
    text-decoration: none;
}

.contentWrapper h2 {
    color: var(--ark-primary-black-figma);
    font-size: var(--ark-24-font-size);
    line-height: var(--ark-30-line-height);
    font-weight: bold;
    padding-bottom: 0.625rem;
}

.contentWrapper h3 {
    color: var(--ark-primary-black-figma);
    font-size: var(--ark-20-font-size);
    line-height: var(--ark-28-line-height);
    font-weight: bold;
    padding-bottom: 0.625rem;
}

.contentWrapper ul {
    list-style: disc;
    padding-bottom: 0.625rem;
    padding-left: 20px;
    margin-left: 20px;
}

.contentWrapper ul li {
    color: var(--ark-primary-black-figma);
    font-size: var(--ark-18-font-size);
    line-height: var(--ark-24-line-height);
}

.contentWrapper em {
    font-style: italic;
}

.contentWrapper iframe {
    width: 100%;
    aspect-ratio: 16/9;
    height: unset;
}

.sidebar {
    width: 334px;
    color: var(--ark-primary-black-figma);
}

.sidebar p {
    font-weight: 700;
    font-size: var(--ark-24-font-size);
    line-height: var(--ark-34-line-height);
    margin-bottom: 16px;
}

.sidebar a {
    color: currentColor;
    font-size: var(--ark-18-font-size);
    line-height: var(--ark-25-line-height);
}

.sidebar ul {
    list-style: disc;
}

.sidebar ul li {
    margin-left: 20px;
    margin-bottom: 16px;
}

.sidebar ul li a {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
}

.sidebar .rightAd {
    padding: 0;
    margin-top: 24px;
    margin-bottom: 33px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    text-align: center;
}

.sidebar .socials {
    display: flex;
    align-items: center;
    gap: 20px;
}

.sidebar .social {
    display: inline-flex;
    align-items: center;
    gap: 13px;
    font-weight: 700;
    text-decoration: none;
}

.block {
    margin: 20px auto;
    max-width: 1147px;
}

.textFieldWithImage {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 3px 0;
    color: var(--ark-primary-black-figma);
    font-size: var(--ark-18-font-size);
    line-height: var(--ark-24-line-height);
    padding-bottom: 0.625rem;
    max-width: 1147px;
}

.markdownText {
    padding-right: 20px;
    width: 100%
}

.markdownText p {
    margin-bottom: 10px;
}

.textFieldWithImage a {
    display: inline-block;
}

.textFieldWithImage img {
    width: 100%;
    height: auto;
    object-fit: contain;
}

.sideImageWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.imageContainer, .centralImageWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    overflow: hidden;
}

.centralImageWrapper a, .centralImageWrapper img {
    max-width: 1147px;
    min-width: 400px;
}

.cta {
    font-size: var(--ark-18-font-size);
    margin: 0 auto;
    padding: 40px;
    width: 100%;
    max-width: 1147px;
}

.cta h2 {
    text-align: center;
}

.cta .buttonWrapper {
    display: flex;
    justify-content: center;
}

.categories {
    font-size: var(--ark-20-font-size);
    line-height: var(--ark-30-font-size);
    margin-bottom: 16px;
}

.categories span {
    font-weight: 700;
}

.customOrderedList li p {
    margin: 0;
}

.customOrderedList {
    list-style-type: decimal;
    padding-left: 20px;
    margin-left: 20px;
    list-style-position: outside;
}

.customOrderedList li {
    display: list-item;
}

.underline {
    text-decoration: underline;
}


@media screen and (max-width: 944px) {
    .contentWrapper {
       padding-right: 0;
       padding-left: 0;
    }

    .block {
        padding-right: 40px;
        padding-left: 40px;
    }

    .sidebar {
        width: 100%;
        display: flex;
        gap: 4rem;
    }

    .sidebar .rightAd {
        display: none;
    }

    .sidebar .socials {
        flex-direction: column;
        align-items: start;
    }

    .textFieldWithImage {
        flex-direction: column;
        font-size: var(--ark-16-font-size);
    }

    .markdownText {
        padding-right: 0;
    }

    .sideImageWrapper {
        justify-content: center;
    }

    .centralImageWrapper img,
    .centralImageWrapper a {
        width: 100%;
        height: auto;
        object-fit: contain;
        min-width: auto;
    }

    .cta {
        font-size: var(--ark-16-font-size);
        margin: 0;
        width: 100%;
    }

    .buttonWrapper {
        width: 50%;
        margin: 0 auto;
    }
}

@media screen and (max-width: var(--ark-small-mobile-figma-600)) {
    .container {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .block {
        padding: 0 15px;
    }

    .contentWrapper {
        padding-right: 0;
        padding-left: 0;
    }
    
    .contentWrapper p {
        font-size: var(--ark-16-font-size);
    }

    .contentWrapper a {
        font-size: var(--ark-16-font-size);
    }

    .contentWrapper h2 {
        font-size: var(--ark-20-font-size);
        line-height: var(--ark-28-line-height);
    }

    .contentWrapper h3 {
        font-size: var(--ark-18-font-size);
        line-height: var(--ark-24-line-height);
    }

    .contentWrapper ul {
        padding-left: 1.5rem;
    }

    .contentWrapper ul li {
        font-size: var(--ark-16-font-size);
    }

    .sidebar {
        display: grid;
        grid-template-columns: 1fr;
    }

    .sidebar .rightAd {
        display: initial;
    }
}
