@import "../../../../../../variables.css";

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    background-color: var(--ark-hover-blue-transparent-figma);
    gap: 16px;
    border-radius: 10px;
    color: var(--ark-secondary-solid-lightgrey-figma);
}

.container.twoSubscriptions {
    background-color: var(--ark-orange-figma);
    color: var(--ark-primary-royal-blue-figma);
}

.container p {
    font-size: var(--ark-20-font-size);
    line-height: var(--ark-28-line-height);
    text-align: center;
}

.container p.help {
    line-height: var(--ark-22-line-height);
}

.link {
    color: var(--ark-main-cyan-figma);
}

.twoSubscriptions .link {
    color: var(--ark-main-blue-figma);
}

/* 1359 */
@media (max-width: var(--ark-medium-desktop-figma-below-1359)) {
    .container {
        padding: 36px;
    }

    .container.twoSubscriptions {
        padding: 24px;
    }
}

/* 599 */
@media (max-width: var(--ark-small-mobile-figma-below-599)) {
    .container p {
        font-size: var(--ark-16-font-size);
        line-height: var(--ark-20-line-height);
    }
}