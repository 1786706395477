@import '../../../variables.css';

.HtmlNoOverflow, .HtmlNoOverflow body {
    overflow-x: hidden!important;
}

.HtmlNoOverflow .rc-anchor {
    display: none!important;
}

.BodyNoScroll {
    height: 100vh!important;
    max-height: 100vh!important;
    min-height: 100vh!important;
    width: 100vw!important;
    max-width: 100vw!important;
    min-width: 100vw!important;
    overflow: hidden!important;
}

.Root, .Navmenu, .MenuBody, .Button, .__ButtonActive {
    --nav-height: 4rem;
    --buttons-number: 5;
    --button-margin-top: 0.75rem;
    --button-body-height: 3rem;
    --button-select-height: 0.25rem;
    box-sizing: border-box;
    background-color: var(--ark-primary-white-figma);
}

.Root {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    max-height: var(--nav-height);
    overflow: hidden;
    z-index: 99999999;
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
    justify-content: space-between;
    transition: all 0.3s ease-in-out;
}

@media screen and (orientation: landscape) {
    .Root {
        display: none!important;
    }
}

.Root.__MenuOpen {
    max-height: 100vh;
    transition: all 0.3s ease-in-out;
}

.Root.__MenuHidden {
    display: none;
}

.MenuBody {
    width: 100%;
    height: calc(100vh - var(--nav-height));
    min-height: calc(100vh - var(--nav-height));
    display: flex;
    position: absolute;
    bottom: var(--nav-height);
    overflow-y: auto;
}

.Navmenu {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: var(--nav-height);
    max-height: var(--nav-height);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    flex-basis: calc(100% / var(--buttons-number));
}

.Button {
    width: calc(100% / var(--buttons-number));
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-items: flex-start;
    align-items: center;
    position: relative;
    padding-top: var(--button-margin-top);
    padding-bottom: var(--button-select-height);
}

.Button svg[aria-label="shop-icon"] {
    transform: translate(calc((24px - 20px) / 2 + 0.5px), 0); /* centered figma icon 24px svg width is wider than actual 20px path width */
}

.Button:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    bottom: 0;
    left: 50%;
    transition: all 0.3s ease-in-out;
    background-color: var(--ark-primary-blue-figma);
}

.Button.__ButtonActive:after {
    left: 0;
    width: 100%;
    height: var(--button-select-height);
}

.Button svg {
    width: 1.5rem;
    height: 1.5rem;
}

.Button svg[aria-label="menu-icon"] path {
    transform: scale(2);
}

.Button p {
    width: 100%;
    text-align: center;
    font-size: 0.65rem;
    font-weight: 700;
    line-height: 1rem;
    margin-top: 0.25rem;
}

.HtmlNoOverflow input[class*="TextField-search"] {
    border-width: 2px;
    border-color: var(--ark-primary-blue-figma)!important;
}

.HtmlNoOverflow input[class*="TextField-search"]::placeholder {
    color: var(--ark-black-color)!important;
}

.HtmlNoOverflow [class*="TextField-iconContainer-UtqLCLF2"] {
    border-color: var(--ark-primary-blue-figma)!important;
    background-color: var(--ark-primary-blue-figma)!important;
}

.HtmlNoOverflow [class*="TextField-iconContainer-UtqLCLF2"] svg,
.HtmlNoOverflow [class*="TextField-iconContainer-UtqLCLF2"] svg * {
    fill: var(--ark-primary-white-figma)!important;
}

/*COMMENTS IMPROVEMENTS*/
.HtmlNoOverflow [class*="PageDescription-descriptionText"],
.HtmlNoOverflow [id="comments-tabpanel"] {
    padding-left: 0;
    padding-right: 0;
}

/*COLLECTIONS IMPROVEMENTS*/
.HtmlNoOverflow [class*="CollectionsTab-loaderWrapper"] > div {
    transform: scale(1.5);
    filter: hue-rotate(271deg);
}

/*SEARCH IMPROVEMENTS*/
.HtmlNoOverflow [class*="GamesSearchTab-tabContainer"] [class*="GamesSearchTab-searchFieldContainer"] + div {
    display: flex!important;
    flex-direction: column;
    flex-wrap: nowrap;
}
.HtmlNoOverflow [class*="SearchResultList-list"] > div,
.HtmlNoOverflow [class*="SearchResultList-list"] > div > a {
    width: 100%;
}
.HtmlNoOverflow [class*="SearchResultList-list"] [class*="GameCard-gameCardWrapper"] {
    width: calc(100% - 1rem);
}
.HtmlNoOverflow [class*="SearchResultList-list"] [class*="GameCard-gameCard"] {
    min-width: calc(100% - 1.25rem);
}
.HtmlNoOverflow [class*="SearchResultList-heading"] {
    margin-top: 2rem!important;
    margin-bottom: 1rem!important;
}

/* COLLECTIONS CONTAINER HEIGHT FIX */
.HtmlNoOverflow [class*="CollectionsTab-container"] {
    height: 100%!important;
    overflow-x: hidden!important;
    overflow-y: auto!important;
}
