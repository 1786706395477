@import '../../../variables.css';

.container {
    font-size: var(--ark-16-font-size);
    line-height: var(--ark-20-line-height);
    background: var(--ark-hover-light-cyan-figma);
    border-radius: 30px 10px 10px 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 24px 0 60px;
    position: absolute;
    color: var(--ark-primary-royal-blue-figma);
    left: -4px;
    height: 54px;
    width: 385px;
    top: -3px;
    opacity: 0;
    transition: 0.5s;
}

.container.visible {
    opacity: 1;
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .container {
        display: none;
    }
}