@import "../../../variables.css";

.container {
    width: 100%;
}

.hiddenAdvantagePromo {
    visibility: hidden;
}

.loaderWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12rem;
}

.errorViewWrapper {
    margin-top: var(--ark-spacing-xxl-5);
}