@import '../../../variables.css';

.snackbarWrapper {
    position: fixed;
    /* top: 1rem; */
    z-index: 10000001;
    left: 50%;
    transform: translate(-50%, 0);
    max-width: 768px;
    width: calc(100% - 2rem);
}

.snackbar {
    padding: 1rem;
    border-radius: 10px;
    font-size: 1rem;
    color: var(--ark-primary-white-figma);
    display: flex;
    align-items: center;
    background-color: var(--ark-hover-dark-red-figma);
    transition: none;
    position: relative;
}

.relativeState{
    position: absolute;
}

.snackbar span {
    margin-left: 1rem;
    margin-right: 2rem;
}

.error {
    background-color: var(--ark-hover-dark-red-figma);
}

.success {
    background-color: var(--ark-main-green-figma);
}

.icon {
    width: 100%;
    max-width: 34px;
}

.closeIcon {
    position: absolute;
    right: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 50%;
}
.closeIcon:hover {
    background-color: rgba(249, 249, 249, 0.3);
}
.animated {
    -webkit-animation: fadein 0.5s, fadeout 0.5s 5.5s;
    animation: fadein 0.5s, fadeout 0.5s 5.5s;
    top: 1rem;
}
.animatedIn{
    -webkit-animation: fadein 0.5s;
    animation: fadein 0.5s;
    top: 1rem;
}



/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
    from {
        top: 0;
        opacity: 0;
    }
    to {
        top: 1rem;
        opacity: 1;
    }
}

@keyframes fadein {
    from {
        top: 0;
        opacity: 0;
    }
    to {
        top: 1rem;
        opacity: 1;
    }
}

@-webkit-keyframes fadeout {
    from {
        top: 1rem;
        opacity: 1;
    }
    to {
        top: 0;
        opacity: 0;
    }
}

@keyframes fadeout {
    from {
        top: 1rem;
        opacity: 1;
    }
    to {
        top: 0;
        opacity: 0;
    }
}
