@import "../../../../variables.css";

.container {
    padding-top: 6rem;
    box-sizing: border-box;
    border: none;
    box-shadow: none;
    background: none;
    height: 100%;
    padding-left: 1rem;
    padding-bottom: 1rem;
}

.content {
    width: 21rem;
    min-height: 12.125rem;
    height: 100%;
    box-shadow: 0 10px 20px var(--ark-extra-orange-50-color);
    background-color: var(--ark-neutral-white);
    border-radius: 1.25rem;
    padding: 1.5rem;
    padding-top: 4rem;
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.image {
    width: 8rem;
    height: 6.5rem;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 50%;
    transform: translate(-50%, -64%);
}

.caption {
    font-size: var(--ark-24-font-size);
    line-height: var(--ark-h3-line-height);
    font-weight: var(--ark-font-weight-bold);
    color: var(--ark-gray-dark-color);
    text-align: center;
    margin: 0;
    text-transform: uppercase;
    transition: color 0.166s linear;
    height: 3.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.caption:hover {
    color: var(--ark-primary-blue-figma);
}


.description {
    font-size: var(--ark-20-font-size);
    line-height: var(--ark-32-line-height);
    font-weight: var(--ark-font-weight-bold);
    color: var(--ark-gray-medium-color);
    text-align: left;
    margin-top: 0;
    margin-bottom: 1rem;
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .container {
        padding-top: 3.25rem;
    }

    .image {
        transform: translate(-50%, -40%);
    }

    .content {
        width: 12.125rem;
        box-sizing: border-box;
        padding-left: 1rem;
        padding-right: 0.875rem;
        padding-bottom: 0.375rem;
        padding-top: 4rem;
    }

    .caption {
        font-size: var(--ark-20-font-size);
        line-height: var(--ark-32-line-height);
        font-weight: var(--ark-font-weight-bold);
    }

    .description {
        font-size: var(--ark-16-font-size);
        line-height: var(--ark-22-line-height);
        font-weight: var(--ark-font-weight-bold);
        margin-bottom: 0.375rem;
    }
}

@media (max-width: var(--ark-small-mobile-576)) {
    .content {
        height: 12.125rem;
    }
}

.rowModeContainer {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 0;    
    padding-left: 0;
    width: 20.5rem;
    height: auto;
}

.rowModeContent {
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: auto;
    border: 2px solid var(--ark-extra-orange-50-color);
    width: 20.5rem;
    height: 5rem;
    justify-content: flex-start;
    color: var(--ark-black-color);
    transition: background 0.166s linear;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    /* fix for Safari https://gist.github.com/ayamflow/b602ab436ac9f05660d9c15190f4fd7b */
    mask-image: -webkit-radial-gradient(white, black);
    background-color: var(--ark-neutral-white);
}

.rowModeContent::before {
    content: ' ';
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    transition: transform .384s var(--ark-ease-in-out-sine);
    background-color: var(--ark-extra-orange-color);
}

.rowModeContent::after {
    content: ' ';
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    transform: translateX(-100%);
    transition: transform .384s var(--ark-ease-in-out-sine);
    background-color:  var(--ark-neutral-white);
}

.rowModeContent:hover::before {
    transform: translateX(0);
    z-index: 1;
}

.rowModeContent:hover::after {
    z-index: 0;
}

.rowModeContent p{
    z-index: 102;
}

.rowModeContent p:hover{
    color: inherit;
}

.rowModeContent:hover {
    background-color: var(--ark-neutral-white);
    color: var(--ark-neutral-white);
}

.rowModeImage {
    position: static;
    height: 3.5rem;
    width: 4.3125rem;
    transform: none;
    margin-left: var(--ark-spacing-xxs);
 }

.rowModeCaption {
    font-size: var(--ark-18-font-size);
    margin-left: var(--ark-spacing-m);
    color: inherit;
}

.rowModeCaption:hover {
    color: inherit;
}