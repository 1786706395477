@import '../../../variables.css';

.loaderWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tabList {
    display: flex;
    width: 100%;
    margin-top: -4rem;
    background-color: rgba(11, 8, 80, 1);
}

.tabList button {
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
}

.tabPanel {
    min-height: inherit;
    position: relative;
    background-color: inherit;
}

@media (max-width: 420px) {
    .tabList button {
        flex-basis: auto;
    }

    .tabList button:first-of-type {
        flex-grow: 1;
    }

    .tabList button:last-of-type {
        flex-grow: 0;
    }
}
