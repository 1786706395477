@import '../../variables.css';

.body {
    position: relative;
    background-color: var(--ark-neutral-white);
    top: calc((var(--ark-header-height) * 2));
    /*padding-top: 1px;*/
    /* for fixed header */
}

.body.gamePage {
    top: 0;
    padding-top: calc((var(--ark-header-height) * 2));
}

.body .headerSearch {
    display: none;
}

.headerNotFixed {
    padding-top: 0;
}

.preAnimate {
    transform: translate(0);
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px var(--ark-neutral-white) inset !important;
}

.mobileMenu {
    display: none;
}

.mobileMenu.hidden {
    display: none !important;
}

.mobileMenu.fixedPosition {
    position: fixed;
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .body {
        padding-top: var(--ark-mobile-header-height);
        top: 0;
    }
    
    .body.gamePage {
        padding-top: var(--ark-mobile-header-height);
        top: 0;
    }

    .body .headerVisible .headerSearch {
        display: block;
    }

    .isOpened {
        transform: translate(-100%);
        transition: transform 0.6s var(--ark-ease-in-out-sine);
    }

    .isAnimated {
        transition: transform 0.664s var(--ark-ease-in-out-sine);
    }

    .mobileMenu.visible {
        position: fixed;
        display: block;
    }

    .mobileMenu {
        position: fixed;
        z-index: 1000000;

        width: 100%;
        top: 0;
        left: 0;
    }
}

@media (max-width: var(--ark-small-desktop-figma-1024)) and (orientation: landscape) {
    .body.gamePage {
        padding-top: 0;
    }

    .mobileMenu {
        width: calc(100% - 5.625rem);
        margin-left: 5.625rem;
    }
}

@media (max-width: var(--ark-large-mobile-768)) and (orientation: landscape) {
    .mobileMenu {
        padding-left: 1.25rem !important;
        left: 4.5rem !important;
        width: calc(100% - 4.5rem) !important;
        margin-left: 0 !important;
    }
}

@media (min-width: var(--ark-extra-small-figma)) {
    .mobileMenu {
        padding: 0 1.25rem;
        padding-top: 0.5rem;
    }
}

@media (min-width: var(--ark-large-mobile-768)) and (max-width: var(--ark-small-desktop-figma-1024)) {
    ​​​ .mobileMenu {
        display: flex;
        justify-content: center;
        padding: 0;
        padding-top: 0.5rem;
    }

    .headerSearch {
        width: 33.5rem;
        margin: 0 auto;
    }
}

@media (max-width: var(--ark-med-small-mobile-480)) {
    .body.gamePage {
        padding-top: 0;
    }
}
