@import '../../../../../../variables.css';

.container {
    display: flex;
    gap: 40px;
    flex-direction: column;
    justify-content: center;
    padding: var(--ark-spacing-xxl-3) var(--ark-spacing-xxl-8) var(--ark-spacing-xxl-8);
    background-color: #2D3BA1;
    background-image: url(./illustrations/subscription/plus_sub_tab_background.webp);
    background-repeat: no-repeat;
    background-size: contain;
}

.container > * {
    width: 100%;
    max-width: 33rem;
    min-width: fit-content;
}

@media (max-width: var(--ark-small-mobile-figma-below-599)) {
    .container {
        gap: var(--ark-spacing-s);
        padding: var(--ark-spacing-s) var(--ark-spacing-s) var(--ark-spacing-xxl-7);
    }
}
