@import url('../../../variables.css');

.loader {
    margin-right: 4rem;
}

@media (max-width: var(--ark-large-desktop-figma-1440)) {
    .loader {
        margin-right: 1rem;
    }
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .loader {
        margin-right: 0;
    }
}
