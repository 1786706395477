@import '../../../../../variables.css';

::-ms-reveal {
    display: none;
}

.m30bottom{
    margin-bottom: 30px;
}

.formContainer{
    position: relative;
    width: 100%;
    margin-top: 30px;
}

.inputWrapper {
    margin-bottom: var(--ark-spacing-m);
    position: relative;
    width: 80%;
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {

    .inputWrapper {
        width: 100%;
    }
    
}
