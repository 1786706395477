@import '../../../../variables.css';

.heading {
    grid-column: 1/3;
    grid-row: 1/2;
}

.sliderContainer {
    grid-row: 3/4;
}

.list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 24px;
    column-gap: 40px;
}

@media screen and (max-width: 690px) {
    .list {
        grid-template-columns: 1fr;
        justify-items: center;
        row-gap: 10px;
    }
}