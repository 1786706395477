@import "../../../../variables.css";

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    flex-direction: column;
}

.thanking {
    font-size: var(--ark-16-font-size);
    line-height: var(--ark-22-line-height);
    font-weight: var(--ark-font-weight-bold);
    margin: 0;
    margin-bottom: 1.875rem;
    text-align: center;
}

.statusMessageOk {
    font-size: var(--ark-22-font-size);
    line-height: var(--ark-32-line-height);
    font-weight: var(--ark-font-weight-bold);
    margin: 0;
    margin-bottom: 1.625rem;
    text-align: center;
}

.image {
    width: 14.125rem;
    height: 9.875rem;
}
