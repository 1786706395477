@import "../../../variables.css";

.container {
    background: #EDF2FA;
    border-radius: 10px;
    width: 252px;
    height: 204px;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    margin-left: 16px;
}

.container:hover {
    box-shadow: 0px 3px 14px rgba(0, 66, 187, 0.4);
}

.info {
    padding-left: 36px;
}

.heading {
    color: var(--ark-main-blue-figma);
    font-weight: bold;
    font-size: var(--ark-28-font-size);
    margin-bottom: 12px;
}

.price {
    font-size: var(--ark-24-font-size);
    color: var(--ark-primary-black-figma);
    font-weight: bold;
}

.monthLabel {
    font-size: var(--ark-18-font-size);
    color: var(--ark-primary-black-figma);
}

.btnRow {
    display: flex;
    justify-content: center;
    margin-top: 24px;
}

.btnRowMonthly {
    margin-top: 32px;
    /* only for align in Monthly block */
}

.btnSubscribe {
    padding: 10px 20px;
}

/****/

.container.managePlanStyles {
    display: grid;
    width: 380px;
    height: 150px;
    padding: 0;
    margin-top: 0;
    margin-left: 0;
    grid-template-areas:
        "info btnRow"
}

.container.managePlanStyles .info {
    grid-area: info;
    padding-left: 0;
    align-self: center;
    justify-self: center;
}

.container.managePlanStyles .btnRow {
    grid-area: btnRow;
    margin-top: 0;
    align-self: center;
    justify-self: center;
}


.container.managePlanStyles.managePlanUpdate {
    grid-template-areas:
        "info info"
}

/*****Media *******/
@media (max-width: var(--ark-small-mobile-figma-below-599)) {
    .container {
        margin-left: 0;
        margin-top: 16px;
        width: 288px;
        height: 124px;
    }

    .container.managePlanStyles {
        display: grid;
        width: 288px;
        height: 96px;
        padding: 16px;
        margin-bottom: 24px;
        grid-template-areas:
            " info btnRow"
            " info btnRow"
    }

    .container.managePlanStyles .info .valuesRow {
        flex-direction: column;
    }

    .info {
        justify-content: center;
        display: flex;
        padding-left: 0;
    }

    .monthLabel {
        font-size: var(--ark-16-font-size);
    }

    .heading {
        margin-right: 1rem;
        margin-bottom: 8px;
        font-size: var(--ark-24-font-size);
    }

    .info .valuesRow {
        display: flex;
        align-items: baseline;
    }

    .btnRowMonthly {
        margin-top: 0.5rem;
        /* only for align in Monthly block */
    }
    .price {
        font-size: var(--ark-20-font-size);
    }
}