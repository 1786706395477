@import '../../../variables.css';

.splitStyle {
    display: flex;
}

.splitStyle .avatarWithGems {
    padding: 15px 20px;
}

.avatarWithGems {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: var(--ark-font-weight-bold);
    font-size: var(--ark-20-font-size);
    color: var(--ark-neutral-black);
    border: 2px solid var(--ark-neutral-black);
    border-radius: 40px;
    justify-content: space-between;
    max-height: 56px;
    cursor: pointer;
}

.avatarWithGems:hover:not(.avatarHover) {
    border: 2px solid var(--ark-extended-solid-blue);
}

.avatarWithGems {
    color: var(--ark-neutral-black);
}

.avatarWithGemsImg {
    margin-left: 7px;
    margin-right: 5px;
    width: 28px;
}

.gemCounter {
    display: flex;
    align-items: center;
    min-width: 80px;
}

.loaderWrapper {
    margin: 0 auto;
}

.avatarWithGemsAdj {
    margin-left: 12px;
    margin-right: -10px;
}
