@import "../../../../../../variables.css";

.container {
    display: flex;
    flex-direction: row;
    gap: 75px;
    justify-content: center;
    padding: 16px 0;
}

.point {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 11px;
    position: relative;
    width: 147px;
    font-weight: var(--ark-font-weight-bold);
    font-size: var(--ark-20-font-size);
    line-height: var(--ark-28-line-height);
    color: var(--ark-primary-white-figma);
    text-align: center;
}

.iconBlock {
    transform: skewX(
            -10deg
    );
    width: 95px;
    height: 74px;
    background: #0042BB;
    border-radius: 20px;
}

.icon {
    position: absolute;
    top: 15px;
}

/* 1359 */
@media (max-width: var(--ark-medium-desktop-figma-below-1359)) {
    .container {
        gap: 35px;
    }
}

/* 719 */
@media (max-width: var(--ark-medium-mobile-figma-below-719)) {
    .point {
        font-size: var(--ark-14-font-size);
        line-height: var(--ark-18-line-height);
        width: 33%;
        max-width: 145px;
    }
}

/* 599 */
@media (max-width: var(--ark-small-mobile-figma-below-599)) {
    .iconBlock {
        width: 70px;
        height: 50px;
        border-radius: 10px;
    }

    .icon {
        width: 34px;
        height: 34px;
        top: 9px;
    }

    .container {
        gap: 15px;
    }
}
