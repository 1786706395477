@import "../../../variables.css";

.container{
    position: relative;
    display: inline-block;
}
.content{
    position: absolute;
    top: 48%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: var(--ark-20-font-size);
    color:var(--ark-primary-royal-blue-figma);
    font-weight: 800;
    text-align: center;
    /* -webkit-text-stroke: 0.1px #fff; */
    text-shadow: 
    0.5px   0  0   #fff, 
   -0.5px   0  0   #fff, 
    0    0.5px 0   #fff, 
    0   -0.5px 0   #fff, 
    0.25px  0.25px 0   #fff, 
   -0.25px -0.25px 0   #fff, 
    0.25px -0.25px 0   #fff, 
   -0.25px  0.25px 0   #fff,
    0.25px  0.25px 3px #fff;
}


