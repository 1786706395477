@import "../../../../../../variables.css";

.container {
    background: var(--ark-neutral-white);
    border-radius: 16px;
    width: 255px;
    border: 1px solid #D1D0D0;
    position: relative;
    z-index: 1;
}

.content {
    height: 222px;
    padding: 24px;
    display: flex;
    flex-direction: column;
}

.title {
    font-size: var(--ark-32-font-size);
    line-height: var(--ark-48-line-height);
    font-weight: var(--ark-font-weight-extraBold);
    color: var(--ark-black-color);
}

.priceTitle {
    margin-top: 8px;
    font-size: var(--ark-32-font-size);
    line-height: var(--ark-18-line-height);
    font-weight: var(--ark-font-weight-extraBold);
    color: var(--ark-black-color);
}

.priceTitle span {
    font-size: var(--ark-18-font-size);
    line-height: var(--ark-18-line-height);
    font-weight: var(--ark-font-weight-regular);
}

.priceSubtitle {
    margin-top: 8px;
    font-size: var(--ark-16-font-size);
    line-height: var(--ark-24-line-height);
    font-weight: var(--ark-font-weight-regular-bolder);
    color: rgba(78, 75, 76, 1);
}

.btn {
    position: relative;
    width: 207px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--ark-neutral-white);
    margin: 16px auto 0;
    font-family: 'Readex Pro', sans-serif;
    font-size: var(--ark-18-font-size);
    line-height: var(--ark-24-line-height);
    font-weight: var(--ark-font-weight-bold);
    color: var(--ark-black-color);
    border: 2px solid var(--ark-black-color);
    border-radius: 24px;
    white-space: nowrap;
}

.btn:hover {
    background-color: var(--ark-black-color);
    color: var(--ark-neutral-white);
}

.container.promotional::after {
    content: "";
    position: absolute;
    bottom: -1px;
    left: -1px;
    right: -1px;
    top: -1px;
    background:
            linear-gradient(#FFFFFF, #FFFFFF) padding-box,
            linear-gradient(281.95deg, #694B14 20.08%, #FFD04D 36.15%, #614717 65.77%, #FFE6B8 86.36%);
    border: 1px solid transparent;
    border-radius: 16px;
    z-index: -11;
}

.cardHeader {
    width: 100%;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--ark-24-font-size);
    line-height: var(--ark-40-line-height);
    font-weight: var(--ark-font-weight-extraBold);
    color: var(--ark-neutral-white);
    background-color: #9912D0;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}



.container.promotional:hover {
    box-shadow: 0 2px 40px rgba(214, 170, 63, 0.7);
}

.container.promotional .btn {
    background-color: #12B76A;
    color: var(--ark-neutral-white);
    border: unset;
}

.container.promotional .btn::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 24px;
    bottom: -3px;
    background-color: #027A48;
    z-index: -1;
}

.container.promotional .btn:hover {
    background-color: #027a48;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}
