@import '../../../variables.css';

.validationBlock {
    display: flex;
    align-items: center;
    width: 100%
}

.validationBlock svg {
    min-width: 20px;
    min-height: 20px;
}

.validationBlock span {
    color: var(--ark-primary-red);
    font-size: var(--ark-14-font-size);
    padding-left: 4px;
}
