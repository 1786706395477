@import "../../../variables.css";

.inputContainer {
    display: flex;
    position: relative;
    flex-direction: column;
}

.inputLabel {
    position: absolute;
    top: -0.625rem;
    background-color: white;
    padding: 0 0.25rem;
    margin-left: 0.5rem;
    font-size: var(--ark-14-font-size);
    font-weight: var(--ark-font-weight-regular);
    line-height: var(--ark-label1-line-height);
    color: var(--ark-gray-medium-color);
    opacity: 0;
    transition: opacity .2s ease;
}

.input {
    font-size: var(--ark-18-font-size);
    font-weight: var(--ark-font-weight-regular);
    line-height: normal;
    color: var(--ark-black-color);
    flex-grow: 1;
    height: 3rem;
    box-sizing: border-box;
    border-radius: 0.625rem;
    box-shadow: none;
    border: 1px solid var(--ark-gray-light-color);

    padding-top: 0.8125rem;
    padding-left: 0.75rem;

    padding-right: 2.2rem;
    padding-bottom: 0.8125rem;
}

.input:hover {
    border: 1px solid var(--ark-primary-blue-figma);
}

.input::-ms-clear {
    display: none;
}

.input.search:hover ~ .iconContainer {
    color: var(--ark-neutral-white);
    background-color: var(--ark-primary-blue-figma);
    border-color: var(--ark-primary-blue-figma);
}

.input:focus {
    border: 1px solid var(--ark-primary-blue-figma);
    outline: 1px solid transparent;
}

.input.search:focus {
    border-right: none;
}

.inputContainer:not(.searchField) .inputLabel.visibleLabel {
    opacity: 1;
}

.inputContainer.inFocus .inputLabel {
    color: var(--ark-primary-blue-figma);
}

.inputContainer.invalid .inputLabel {
    color: var(--ark-arkadium-red-color);
}

.input::placeholder {
    font-size: var(--ark-18-font-size);
    font-weight: var(--ark-font-weight-regular);
    line-height: normal;
    color: var(--ark-gray-medium-color);
}

.input:focus::placeholder {
    color: transparent;
}

.input.search {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right-color: var(--ark-gray-extra-light-color);
}

.input.search:focus ~ .iconContainer {
    color: var(--ark-neutral-white);
    border-color: var(--ark-primary-blue-figma);
    background-color: var(--ark-primary-blue-figma);
}

.clearButton {
    width: 2.125rem;
    height: 2.125rem;
    position: absolute;
    top: 50%;
    right: 0.4375rem;
    transform: translateY(-45%);
    border: 0;
    box-shadow: none;
    cursor: pointer;
    color: var(--ark-gray-dark-color);
    background: none;
    padding: 0;
}

.input.search ~ .clearButton {
    right: 3.3rem
}

.inputWithIcon {
    display: flex;
    position: relative;
}

.inputContainer.invalid .clearButton {
    margin-right: 0;
    right: 2.125rem;
}

.inputContainer.invalid .input.search ~ .clearButton {
    margin-right: 0;
    right: 2.6375rem;
}

.iconContainer {
    flex-shrink: 0;
    width: 3rem;
    height: 3rem;
    background-color: var(--ark-gray-extra-light-color);
    border-top-right-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    border: 1px solid var(--ark-gray-light-color);
    border-left: none;
    box-sizing: border-box;
    color: var(--ark-gray-dark-color);
    display: flex;
    align-items: center;
    justify-content: center;
}

.inputContainer.invalid .input {
    border-color: var(--ark-arkadium-red-color);
}

.errorText {
    margin: 0;
    display: block;
    margin-top: 0.125rem;
    margin-left: 0.5rem;
    font-size: var(--ark-12-font-size);
    font-weight: var(--ark-font-weight-regular);
    line-height: var(--ark-14-line-height);
    color: var(--ark-arkadium-red-color);
}

.warningIcon {
    width: 2.125rem;
    height: 2.125rem;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}
