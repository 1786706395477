@import './fonts.css';
@import './reset.css';
@import './variables.css';

html {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
}

body {
    font-family: var(--ark-font-family);
}

strong {
    font-weight: bold;
}

:global(#root) {
    font-size: var(--ark-16-font-size);
}

/**
 * Add styles for focus and focus:visible states.
 * focus works for keyboard AND mouse clicks,
 * focus-visible works ONLY for keyboard clicks.
 */
:focus,
a:focus,
:focus-visible,
a:focus-visible {
    outline: dashed var(--ark-focus-figma) var(--focus-state-outline-width);
}

/**
 * We don't need styles from previous rule
 * for these elements.
 */
input:focus,
textarea:focus,
input:focus-visible,
textarea:focus-visible,
/** These two is for OpenWeb section */
div[data-spot-im-shadow-host]:focus,
div[data-spot-im-shadow-host]:focus-visible {
    outline: 0;
}

/**
 * When focus-visible rule is supported by browser
 * remove outline when :focus but not :focus-visible,
 * so now focus styles will appear only for keyboard clicks.
 * If focus-visible rule is NOT supported focus-visible polyfill
 * will use [data-js-focus-visible], [data-focus-visible-added] attributes.
 */
[data-js-focus-visible] :focus:not([data-focus-visible-added]) {
  outline: none;
}

@media screen and (min-width: 320px) and (max-width: calc(var(--ark-small-desktop-figma-1024) - 1px)) {
    :global(#root) {
        font-size: 14px;
    }
}
