@import "../../../../../../variables.css";

.gemsCardsList {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: var(--ark-spacing-xxl);
    justify-content: space-between;
}

/* width: 0 - 1024 */
@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .gemsCardsList {
        justify-content: space-evenly;
    }
}

@media (max-width: var(--ark-medium-mobile-figma)) and (orientation: landscape) {
    .gemsCardsList {
        grid-template-columns: repeat(2, auto);
    }
}

/* width: 0 - 599 */
@media (max-width: var(--ark-small-mobile-figma-below-599)) {
    .gemsCardsList {
        grid-template-columns: repeat(2, auto);
        grid-gap: var(--ark-spacing-s);
    }
}
