@import '../../../../../variables.css';

.topBlock {
    height: 313px;
    background-image: url(./illustrations/subscription/tabBackground.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
}

.mainInfo {
    display: flex;
    height: 120px;
    align-items: center;
    margin-top: 44px;
}

.advantagesList {
    color: var(--ark-primary-white-figma);
    font-size: var(--ark-16-font-size);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.advantagesList li {
    display: flex;
    align-items: center;
}

.advantagesList li p {
    line-height: var(--ark-20-line-height);
}

.advantagesList li svg {
    min-width: 34px;
}

.advantageLabel {
    color: var(--ark-advantage-yellow-figma);
    font-weight: var(--ark-font-weight-bold);
}

.rightSide {
    margin-left: 14px;
    width: 340px;
}

.aboutSubscription {
    font-size: var(--ark-16-font-size);
    line-height: var(--ark-24-line-height);
    font-weight: lighter;
    text-align: center;
    color: var(--ark-primary-asbestos-figma);
}

.advantagesRow {
    display: flex;
    margin-top: -120px;
    justify-content: center;
    min-height: 15.25rem; /* for loader */
}

.aboutRow {
    margin-top: 36px;
    padding-bottom: var(--ark-spacing-xxl-8);
}

.saveStar {
    position: absolute;
    top: -5px;
    right: -8px;
}

/*****Media *******/
@media (max-width: var(--ark-small-mobile-figma-below-599)) {
    .topBlock {
        height: 358px;
        flex-direction: column;
        justify-content: start;
    }

    .mainInfo {
        margin-top: 16px;
        flex-direction: column;
        height: inherit;
    }

    .leftSide svg {
        height: 58px;
        width: 120px;
    }

    .rightSide {
        margin-top: 16px;
        margin-left: 0;
        width: 288px;
    }

    .advantagesList li {
        margin-bottom: 8px;
    }

    .advantagesRow {
        flex-direction: column;
        align-items: center;
        margin-top: -100px;
        min-height: 24rem; /* for loader */
    }

    .aboutSubscription {
        width: 288px;
        font-size: var(--ark-16-font-size);
        line-height: var(--ark-20-line-height);
    }

    .aboutRow {
        display: flex;
        justify-content: center;
    }
}
