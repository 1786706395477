@import "../../../../variables.css";

.tabContainer {
    height: 100%;
    background-color: #2d3da3;
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .gemsTabDarkBackground {
        background-color: #2F3DA3;
    }
}

@media (max-width: var(--ark-small-mobile-figma-600)) {
    .tabContainer {
        background-color: var(--ark-neutral-white);
    }

    .gemsTabDarkBackground {
        background-color: #2F3DA3;
    }
}
