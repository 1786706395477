@import "../../../../../../variables.css";

.container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 48px;
    padding: 48px;
    background-color: var(--ark-primary-royal-blue-figma-70);
    border-radius: var(--ark-border-radius);
}

.title {
    font-weight: var(--ark-font-weight-bold);
    font-size: var(--ark-36-font-size);
    line-height: var(--ark-36-line-height);
    color: var(--ark-main-cyan-figma);
    margin-bottom: 12px;
}

.description {
    font-size: var(--ark-18-font-size);
    line-height: var(--ark-24-line-height);
    color: var(--ark-primary-white-figma);
    margin-bottom: 16px;
}

.btnSubscribe{
    padding: 10px 20px;
    width: fit-content;
}

.logoImg {
    width: 155px;
    height: 74px;
}

/* 1359 */
@media (max-width: var(--ark-medium-desktop-figma-below-1359)){
    .title {
        font-size: var(--ark-24-font-size);
        line-height: var(--ark-24-line-height);
        margin-bottom: 12px;
    }

    .description {
        font-size: var(--ark-14-font-size);
        line-height: var(--ark-18-line-height);
    }
}

/* 1023 */
@media (max-width: var(--ark-small-desktop-figma-below-1023)){
    .container {
        gap: 36px;
        padding: 43px 36px;
    }

    .logoImg {
        width: 126px;
        height: 60px;
    }
}

/* 719 */
@media (max-width: var(--ark-medium-mobile-figma-below-719)){
    .container {
        gap: 24px;
        padding: 32px 24px;
    }

    .logoImg {
        width: 97px;
        height: 46px;
    }
}

/* 599 */
@media (max-width: var(--ark-small-mobile-figma-below-599)){
    .avatar {
        order: 1;
        flex: 0 0 110px;
    }

    .text {
        order: 3;
        flex: 0 0 100%;
        align-items: center;
        display: flex;
        flex-direction: column;
    }

    .logo {
        order: 2;
        flex: 0 0 97px;
        align-items: center;
        display: flex;
        margin-left: auto;
    }

    .container {
        padding: 24px 16px;
        flex-wrap: wrap;
    }
}