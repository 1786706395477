@import '../../../variables.css';

.divider {
    font-weight: var(--ark-font-weight-bold);
    color: var(--ark-black-color);
    font-size: var(--ark-14-font-size);
    line-height: var(--ark-16-font-size);
    position: relative;
    display: inline-flex;
}

.dividerLine {
    border-bottom: 0.0625rem solid var(--ark-neutral-disabled);
    width: 11.5rem;
    top: -0.4375rem;
    position: relative;
}

.dividerLine.left {
    margin-right: 1.625rem;
}

.dividerLine.right {
    margin-left: 1.625rem;
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .divider {
        width: 100%;
        max-width: 500px;
    }

    .dividerLine {
        width: 100%;
    }
}
