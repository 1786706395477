@import '../../../../../../variables.css';

.advantagesList {
    color: var(--ark-primary-white-figma);
    font-size: var(--ark-16-font-size);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 45px;
}

.advantagesList li {
    display: flex;
    align-items: center;
}

.advantagesList li p {
    line-height: var(--ark-20-line-height);
}

.advantagesList li svg {
    min-width: 34px;
}

.noMargin {
    margin-top: 0
}


/*****Media *******/
@media (max-width: var(--ark-small-mobile-figma-below-599)) {
    .advantagesList li {
        margin-bottom: 4px;
    }
}
