@import '../../../../../variables.css';

.wrapper {
    display: flex;
    justify-content: center;
    height: auto;
}

.tabContent {
    color: var(--ark-primary-black-figma);
    overflow: auto;
    text-align: center;
    flex-direction: column;
    width: 452px;
    display: flex;
    align-items: center;
    padding: 50px 0;
}

.tabContentFullWidth {
    width: 100%;
}

.formContent {
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
}

.header {
    font-size: var(--ark-28-font-size);
    line-height: var(--ark-34-line-height);
    font-weight: var(--ark-font-weight-light);
}

.subHeader,
.receiveText,
.stillHelpText,
.noticeText {
    font-size: var(--ark-18-font-size);
    line-height: var(--ark-24-line-height);
    font-weight: var(--ark-font-weight-light);
}

.subHeader {
    margin-top: 1.25rem;
    max-width: 480px;
}

.receiveText {
    margin-top: 1.875rem;
}

.stillHelpText {
    margin-top: 1.25rem;
}

.noticeText {
    margin-top: 20px;
}

.subHeaderEmail {
    font-size: var(--ark-18-font-size);
    line-height: var(--ark-24-line-height);
    font-weight: var(--ark-font-weight-bold);
}

.btnOk {
    width: 161px;
    min-height: 48px;
    margin-top: 1.875rem;
}

.subHeaderTextButton {
    font-size: var(--ark-20-font-size);
    line-height: var(--ark-32-line-height);
    font-weight: var(--ark-font-weight-regular);
}

.textButton {
    font-size: var(--ark-18-font-size);
    line-height: var(--ark-24-line-height);
    font-weight: var(--ark-font-weight-regular);
}

.subHeaderButton {
    font-size: var(--ark-20-font-size);
    line-height: var(--ark-32-line-height);
    font-weight: var(--ark-font-weight-regular);
}

/*****SOCIAL SIGN UP******/
.userName {
    font-size: var(--ark-28-font-size);
    line-height: var(--ark-34-line-height);
    font-weight: var(--ark-font-weight-bold);
}

.takeBreak {
    font-size: var(--ark-18-font-size);
    line-height: var(--ark-24-line-height);
    font-weight: var(--ark-font-weight-bold);
    margin-top: 50px;
}

/*******RESEND OK buttons */

.subHeaderButton {
    font-size: var(--ark-20-font-size);
    line-height: var(--ark-32-line-height);
    font-weight: var(--ark-font-weight-regular);
}

.buttonsRow,
.newVersion {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 40px;
}

.buttonsRow button {
    width: 150px;
    height: 48px;
}

.newVersion button {
    width: 100%;
    height: 48px;
}

/*******************/

.input,
.inputReset {
    width: 20rem;
    height: 3rem;
}

.inputReset {
    margin-top: 20px;
}

.inputRow {
    align-items: center;
    margin-top: 30px;
    width: 100%;
}

.rememberRow {
    margin-top: 30px;
    font-size: var(--ark-18-font-size);
    line-height: var(--ark-24-line-height);
    font-weight: var(--ark-font-weight-light);
}

.buttonRow {
    align-items: center;
    justify-content: center;
}

.submitButton {
    width: 128px;
    height: 48px;
    margin-top: 40px;
}

.resendBtnsRow {
    width: 340px;
}

.validationMessageCustom {
    margin-left: 0;
    padding-left: 15px;
    text-align: left;
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .wrapper {
        align-items: center;
        height: auto;
    }

    .tabContent {
        padding: 0;
        padding-bottom: 25px;
    }
}

@media (max-width: var(--ark-large-mobile-768)) {
    .formContent {
        max-width: 300px;
        width: 90%;
    }
}

@media (max-width: var(--ark-small-mobile-figma-600)) {
    .tabContent {
        width: 85%;
        padding-bottom: 80px;
    }
}
