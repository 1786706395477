@import "../../../../variables.css";

.searchFieldContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.searchInput {
    flex-shrink: 0;
    width: 33.5rem;
}

.defaultHeading,
.hasSearchResultHeading,
.emptySearchHeading {
    font-size: var(--ark-20-font-size);
    line-height: var(--ark-32-line-height);
    color: var(--ark-gray-medium-color);
    font-weight: var(--ark-font-weight-bold);
}

.hasSearchResultHeading {
    color: var(--ark-gray-dark-color);
}

.emptySearchHeading {
    color: var(--ark-primary-blue-figma);
}

.tabContainer {
    padding-left: 3rem;
    padding-right: 3.5rem;
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .tabContainer.extendMediaQuery {
        padding-top: 1rem;
        padding-left: 1.25rem;
        padding-right: 0;
    }

    .extendMediaQuery .searchFieldContainer {
        display: none;
    }

    .extendMediaQuery .defaultHeading,
    .extendMediaQuery .hasSearchResultHeading,
    .extendMediaQuery .emptySearchHeading {
        font-size: var(--ark-16-font-size) !important;
        line-height: var(--ark-22-line-height) !important;
        font-weight: var(--ark-font-weight-bold);
    }
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .searchFieldContainer {
        display: none;
    }

    .defaultHeading,
    .hasSearchResultHeading,
    .emptySearchHeading {
        font-size: var(--ark-16-font-size) !important;
        line-height: var(--ark-22-line-height) !important;
        font-weight: var(--ark-font-weight-bold);
    }

    .tabContainer {
        padding-top: 1rem;
        padding-left: 1.25rem;
        padding-right: 0;
    }
}
