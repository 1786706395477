@import '../../../variables.css';

.wrapper {
    position: relative;
}

.input {
    border: 2px solid var(--ark-primary-black-figma);
    height: 48px;
    width: 100%;
    border-radius: var(--ark-border-radius);
    padding-left: 12px;
    transition: all 0.5s;
    text-overflow: ellipsis;
    padding-right: 34px;
    /* done to avoid overlapping the close icon (34px = width of close icon) */
}

.input::placeholder {
    color: var(--ark-primary-asbestos-figma);
    font-size: var(--ark-18-font-size);
}

.input:focus {
    border: 2px solid var(--ark-primary-blue-figma);
}

.input div {
    height: 100%;
}

.successful {
    border-color: var(--ark-primary-green);
}

.error {
    border-color: var(--ark-primary-red);
}

.disabled {
    background: rgba(196, 196, 196, 0.4);
    border: 2px solid #6c6c6c;
    color: #6c6c6c;
}


.handlerIcon {
    width: 2.125rem;
    height: 2.125rem;
    position: absolute;
    border: 0;
    box-shadow: none;
    cursor: pointer;
    background: none;
    padding: 0;
    right: 5px;
    transform: translateY(23%);
}

.handlerIcon:hover .eyeIconClose {
    color: var(--ark-primary-asbestos-figma);
}

.handlerIcon .eyeIconOpen {
    color: var(--ark-primary-asbestos-figma);
}

.handlerIcon:hover .eyeIconOpen {
    color: var(--ark-primary-black-figma)
}

.errorMessage {
    position: relative;
    bottom: 0;
}
