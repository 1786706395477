@import '../../../variables.css';

.dropdown {
}

.dropdownCaption {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    width: 100%;
}

.dropdownCaption .dropdownCaptionText {
    font-size: var(--ark-28-font-size);
    /* font-family: var(--ark-font-family);
    font-weight: var(--ark-font-weight-bold);
    font-size: var(--ark-32-font-size);
    line-height: var(--ark-40-line-height);
    color: var(--ark-white-50-color); */
}

.dropdownCaption .dropdownIcon {
    height: 2.125rem;
    width: 2.125rem;
    transform: rotate(90deg);
    color: var(--ark-neutral-white);
}

.dropdownList {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.48s var(--ark-ease-in-out-sine);
    margin-left: 0.375rem;
}

.dropdownList ul {
    column-count: 1;
}

.attentionLink {
    color: var(--ark-arkadium-red-color);
}

.twoColumns ul {
    column-count: 2;
}

/* right side of items in footer categories*/
.dropdownList li:nth-child(1n + 14) > button {
    padding-left: 0;
}

.dropdownOpened {
    max-height: 46rem;
}

.switcherOpening.dropdownOpened {
    max-height: 5rem;
}

.switcherOpeningLocales.dropdownOpened {
    max-height: 10rem;
}
