@import '../../../../../variables.css';

.inputWrapper {
    margin-bottom: var(--ark-spacing-m);
    position: relative;
    width: 80%;
}

.wrapper {
    position: relative;
    max-width: 500px;
}

.subHeader {
    max-width: 500px;
}

.signUpBenefits {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}

.signUpBenefits li {
    position: relative;
}

.signUpBenefits li p {
    font-weight: bold;
    font-size: 12px;
    display: inline-block;
    width: 121px;
    text-align: left;
    vertical-align: middle;
}

.signUpBenefits li img {
    margin-right: 5px;
    vertical-align: middle;
}

.policy a {
    color: var(--ark-primary-blue-figma);
    font-size: var(--ark-18-font-size);
    line-height: var(--ark-24-font-size);
    text-align: center;
    text-decoration: none;
}

.submitButton {
    max-width: 127px;
    border-radius: 1.5rem;
    padding: 10px 30px;
    height: 3rem;
    margin-top: 1rem;
}

.dividerBlock {
    margin: 2.5rem 0;
}

.socialButtons {
    display: flex;
    margin-bottom: 20px;
}

.validation {
    position: relative;
    bottom: 0;
}

.validationMessage {
    font-size: var(--ark-14-font-size);
    line-height: var(--ark-16-line-height);
    color: var(--ark-primary-asbestos-figma);
    margin-top: 0.25rem;
    margin-left: 6px;
}

.validationMessageError .dot {
    background-color: var(--ark-primary-red);
}

.validationMessageOk .dot {
    background-color: var(--ark-primary-green);
}

.validationMessageError {
    color: var(--ark-primary-red);
}

.validationMessageOk {
    color: var(--ark-primary-green);
}

.dot {
    height: 0.5rem;
    width: 0.5rem;
    margin-right: 0.5rem;
    background-color: var(--ark-primary-asbestos-figma);
    border-radius: 50%;
    display: inline-block;
}

.checkboxContainer {
    max-width: 20rem;
    margin: 0 auto 1rem;
}

.checkbox {
    min-width: 2.125rem;
    height: 2.125rem;
    color: var(--ark-primary-blue-figma-font);
}

.checkboxLabel {
    font-size: var(--ark-14-font-size);
    line-height: var(--ark-18-line-height);
    color: var(--ark-primary-black-figma);
    font-weight: var(--ark-font-weight-regular);
    margin-left: var(--ark-spacing-xxs);
}

.hideContent {
    display: none;
}

.formWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .inputWrapper {
        width: 100%;
    }

    .inputWrapper:not(:last-child) {
        margin-bottom: 10px;
    }

    .dividerBlock {
        margin: 1.25rem 0;
    }

    .socialButtons {
        flex-direction: column;
        margin-bottom: 2rem;
    }

    .socialButtons iframe:not(:last-child) {
        margin-bottom: 10px;
    }

    .tabContent {
        padding: 0 1rem;
    }
}

@media (max-width: var(--ark-small-mobile-figma-600)) {
    .signUpBenefits li {
        width: 50%;
    }

    .signUpBenefits li:last-child {
        display: none;
    }
}

@media (max-width: var(--ark-new-med-small-mobile-360)) {
    .signUpBenefits li p {
        width: 100px;
    }
}
