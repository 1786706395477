@import '../../../../../variables.css';

.wrapper {
    display: flex;
    flex-direction: column;
    padding: 40px;
}

.mainHeading {
    font-size: var(--ark-30-font-size);
    line-height: var(--ark-32-line-height);
    font-weight: 300;
    text-align: center;
}

.subHeading {
    font-size: var(--ark-24-font-size);
    line-height: var(--ark-28-line-height);
    font-weight: var(--ark-font-weight-bold);
}

.subHeading.top {
    margin-top: 24px;
}

.saveInfo {
    font-size: var(--ark-18-font-size);
    line-height: var(--ark-24-line-height);
    text-align: center;
}

.enjoyRow {
    font-size: var(--ark-18-font-size);
    line-height: var(--ark-24-line-height);
    margin: 16px 0;
}

.annualRow {
    margin-bottom: 24px;
    display: flex;
    justify-content: center;
    margin-top: 24px;
}

.confirmBtnsRow {
    display: flex;
    align-items: center;
    justify-content: center;
}

.confirmBtnsRow .confirmBtn {
    margin-left: 24px;
}

.confirmBtnsRow button {
    padding: 10px 30px;
}

.confirmBlock {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.confirmText {
    text-align: center;
    margin-top: 40px;
    font-size: var(--ark-18-font-size);
    line-height: var(--ark-24-line-height);
    font-weight: var(--ark-font-weight-light);
    width: 430px;
}

.bottomRow {
    margin-top: 40px;
}

.cancelText {
    width: inherit;
}

.cancelBtnsRow {
    margin-top: 40px;
}

.cancelBtn {
    font-size: var(--ark-18-font-size);
    color: var(--ark-main-blue-figma);
    background: none;
    height: auto;
    padding: 0;
    text-decoration: underline;
}

.cancelBtn:hover {
    background: none;
}

/*****Media *******/
@media (max-width: var(--ark-small-mobile-figma-below-599)) {
    .wrapper {
        padding: 16px;
    }

    .confirmBtnsRow {
        flex-direction: column-reverse;
    }

    .confirmBtnsRow button {
        padding: 10px 20px;
    }

    .confirmText {
        width: 100%;
    }

    .confirmBtnsRow .confirmBtn {
        margin-left: 0;
        margin-bottom: 16px;
    }

    .cancelBtnsRow {
        flex-direction: column;
    }

    .cancelBtnsRow .confirmBtn {
        margin-top: 16px;
        margin-bottom: 0;
    }
}
