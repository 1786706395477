@import '../../../../variables.css';

.title {
    font-weight: bold;
    color: var(--ark-primary-black-figma);
    font-size: var(--ark-28-font-size);
    margin-bottom: 0.5rem;
}

.subtitle {
    color: var(--ark-primary-black-figma);
    font-size: var(--ark-20-font-size);
    margin-bottom: 2rem;
}

.btn {
    width: 200px;
}

.wrapper img {
    width: 100%;
}

.content {
    padding: 2rem 0 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media (max-width: var(--ark-small-mobile-figma-600)) {
    .title {
        font-size: var(--ark-20-font-size);
    }
    .subtitle {
        font-size: var(--ark-16-font-size);
        margin-bottom: 2.5rem;
    }
    .btn {
        width: 84px;
        height: 44px;
    }
}

