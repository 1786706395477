@import '../../../variables.css';

.root {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.validationMessage {
    font-size: var(--ark-14-font-size);
    line-height: var(--ark-16-line-height);
    color: var(--ark-primary-asbestos-figma);
    margin-top: 0.25rem;
    margin-left: 6px;
    display: flex;
    align-items: center;
}

.validationMessageError {
    color: var(--ark-primary-red);
}
.validationMessageOk {
    color: var(--ark-primary-green);
}

.mark {
    display: flex;
    justify-content: center;
    width: 1rem;
    margin-right: 0.5rem;
}

.screenReaderOnly {
    height: 1px;
    width: 1px;
    opacity: 0;
    pointer-events: none;
}