@import '../../../variables.css';

.wrapper {
    display: flex;
    align-items: center;
    height: 312px;
    width: calc(100% - 56px); /* 56px - padding on category page */
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    margin: 1.25rem auto 2rem;
}

.wrapper img {
    margin-left: 2.5rem;
}

.content {
    max-width: 584px;
    margin-left: 4rem;
}

.content a {
    font-weight: bold;
    text-decoration: none;
    color: var(--ark-primary-blue-figma);
}

.title {
    font-size: var(--ark-28-font-size);
    margin-bottom: 10px;
    font-weight: bold;
}

.subtitle {
    font-size: var(--ark-18-font-size);
    margin-bottom: 1.25rem;
    line-height: var(--ark-24-line-height);
}

.whiteTheme .title, .whiteTheme .subtitle, .whiteTheme a {
    color: var(--ark-primary-white-figma);
}

.whiteTheme a {
    font-weight: var(--ark-font-weight-bold);
    text-decoration: underline;
}

.defaultTheme .title, .defaultTheme .subtitle {
    color: var(--ark-primary-black-figma);
}

.btn {
    padding: 10px 30px;
}

.loader {
    margin: 1.5rem auto 0;
    display: flex;
    justify-content: center;
}

@media (max-width: var(--ark-medium-desktop-figma-1360)) {
    .content {
        padding-right: 1rem;
        margin-left: 1rem;
    }
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .title {
        font-size: var(--ark-20-font-size);
        margin-bottom: 0.5rem;
    }
    .subtitle {
        font-size: var(--ark-16-font-size);
        margin-bottom: 1rem;
    }
    .content {
        padding-right: 2.5rem;
        margin-left: 1.25rem;
    }
    .wrapper img {
        max-width: 167px;
        max-height: 167px;
    }
    .btn {
        max-height: 44px;
        font-size: var(--ark-16-font-size);
    }
}

@media (max-width: var(--ark-small-mobile-figma-600)) {
    .wrapper {
        align-items: flex-start;
        width: 100%;
        height: auto;
        min-height: 230px;
        margin: 0;
        border-radius: 0;
    }
    .wrapper img {
        display: none;
    }
    .content {
        margin: 1.25rem 0 1.75rem;
        padding: 0 1rem;
    }
    .btn {
        font-size: var(--ark-16-font-size);
        margin: 0 auto;
    }
}
