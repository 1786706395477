@import "../../../variables.css";

.container {
    position: relative;
    border: 1px solid transparent;
    border-radius: 0.625rem;
}

.inputLabel {
    position: absolute;
    top: -0.625rem;
    left: 0;
    background-color: var(--ark-neutral-white);
    padding: 0 0.25rem;
    margin-left: 0.5rem;
    font-size: var(--ark-14-font-size);
    font-weight: var(--ark-font-weight-regular);
    line-height: var(--ark-label1-line-height);
    color: var(--ark-gray-medium-color);
    opacity: 0;
    transition: opacity .2s ease;
}

.inputLabel.visibleLabel {
    opacity: 1;
}

.container.inFocus {
    border: 1px solid var(--ark-primary-blue-figma);
}

.inFocus .inputLabel {
    color: var(--ark-primary-blue-figma);
}

