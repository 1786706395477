@import '../../../variables.css';

.container {
    position: relative;
}

.container:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 70%;
    left: 0;
    bottom: -1px;
    background: linear-gradient(0deg, rgba(11, 8, 80, 1) 13.08%, rgba(0, 0, 0, 0) 88.32%);
}

.image {
    width: 100%;
}

@media (max-width: var(--ark-small-mobile-figma-600)) {
    .image {
        width: auto;
        height: 200px;
    }

    .container {
        display: flex;
        justify-content: center;
        overflow: hidden;
        min-height: 200px;
    }
}