@import '../../../../../variables.css';

.container {
    /* background: #b3d4fc; */
    width: 100%;
}

.chooseTitle {
    font-weight: var(--ark-font-weight-bold);
    font-size: var(--ark-18-font-size);
    line-height: var(--ark-24-line-height);
    text-align: center;
}

.subTitle {
    text-align: center;
}

.avatarsRow {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 30px 0 40px;
}



@media (max-width: var(--ark-small-mobile-figma-600)) {
    .avatarsRow {
        display: grid;
        grid-template-columns: repeat(3, 6rem);
    }
}