@import '../../../variables.css';

.closeIcon {
    position: absolute;
    top: 2rem;
    right: 2rem;
    cursor: pointer;
}

@media (max-width: var(--ark-small-mobile-figma-600)) {
    .closeIcon {
        top: 1rem;
        right: 1rem;
    }
}
