@import "../../../../../../../../variables.css";

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    background-color: rgba(11, 16, 61, 0.9);
    gap: 10px;
    border-radius: 10px;
    color: var(--ark-secondary-solid-lightgrey-figma);
    height: 100%;
    justify-content: center;
}

.container p {
    font-size: var(--ark-20-font-size);
    line-height: var(--ark-28-line-height);
    text-align: center;
}

.container p.small {
    font-size: var(--ark-14-font-size);
    line-height: var(--ark-18-line-height);
}

.link {
    color: #12B76A;
}

/* 599 */
@media (max-width: var(--ark-small-mobile-figma-below-599)) {
    .container {
        padding: 16px;
    }

    .container p {
        font-size: var(--ark-16-font-size);
        line-height: var(--ark-20-line-height);
    }
}
