.progressiveContainer {
    overflow: hidden;
    width: 100%;
    height: 100%;
}

/* 1:1 */
.progressiveContainer.square:before {
    content: "";
    float: left;
    padding-top: 100%;
}

/* 2.16:1 */
.progressiveContainer.wide:before {
    content: "";
    float: left;
    padding-top: 46%;
}

/* 2.7:1 */
.progressiveContainer.promo:before {
    content: "";
    float: left;
    padding-top: 37%;
}

.picture {
    display: block;
    max-width: inherit;
    max-height: inherit;
    min-height: inherit;
}

.picture.vertical {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
}

.colorBlock {
    width: 100%;
    height: 100%;
    background-color: white;
}

.progressiveImageBase {
    display: block;
    height: auto;
    width: 100%;
    max-width: inherit;
    max-height: inherit;
    min-height: inherit;
}

.progressiveImageBase.vertical {
    height: 100%;
    width: auto;
    max-width: none;
}
