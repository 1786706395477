@font-face {
  font-family: 'Nunito Sans';
  src: local('Nunito Sans'), url(fonts/nunitosans-light-webfont.woff2) format('woff2'),
    local('Nunito Sans'), url(fonts/nunitosans-light-webfont.woff) format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: 'Nunito Sans';
  src: local('Nunito Sans'), url(fonts/nunitosans-regular-webfont.woff2) format('woff2'),
    local('Nunito Sans'), url(fonts/nunitosans-regular-webfont.woff) format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: 'Nunito Sans';
  src: local('Nunito Sans'), url(fonts/nunitosans-bold-webfont.woff2) format('woff2'),
    local('Nunito Sans'), url(fonts/nunitosans-bold-webfont.woff) format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: 'Nunito Sans';
  src: local('Nunito Sans'), url(fonts/nunitosans-extrabold-webfont.woff2) format('woff2'),
    local('Nunito Sans'), url(fonts/nunitosans-extrabold-webfont.woff) format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: 'Nunito Sans';
  src: local('Nunito Sans'), url(fonts/nunitosans-black-webfont.woff2) format('woff2'),
    local('Nunito Sans'), url(fonts/nunitosans-black-webfont.woff) format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: 'Nunito Sans';
  src: local('Nunito Sans'), url(fonts/nunitosans-italic-webfont.woff2) format('woff2'),
    local('Nunito Sans'), url(fonts/nunitosans-italic-webfont.woff) format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap
}

@font-face {
  font-family: 'nunito_sanssemibold';
  src: url('public/fonts/nunitosans-semibold-webfont.woff2') format('woff2'),
    url('public/fonts/nunitosans-semibold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: 'Readex Pro';
  src: local('Nunito Sans'), url(fonts/readexpro-regular) format('ttf');
  font-weight: 400;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: 'Readex Pro';
  src: local('Nunito Sans'), url(fonts/readexpro-medium) format('ttf');
  font-weight: 500;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: 'Readex Pro';
  src: local('Nunito Sans'), url(fonts/readexpro-semiBold) format('ttf');
  font-weight: 600;
  font-style: normal;
  font-display: swap
}
