@import "../../../../variables.css";

.container {
    margin-bottom: 3.5rem;
}

.heading {
    font-weight: var(--ark-font-weight-bold);
    font-size: var(--ark-20-font-size);
    line-height: var(--ark-32-line-height);
    color: var(--ark-gray-medium-color);
    margin: 0;
    margin-bottom: 1rem;
}

.grid {
    display: grid;
    grid-template-columns: 17.5rem 2.5rem 17.5rem;
    grid-template-rows: 1fr 2rem;
}

.gridItem:nth-child(2) {
    grid-column-start: 3;
    grid-column-end: 4;
}

.gridItem:nth-child(3) {
    grid-row-start: 3;
    grid-row-end: 4;
}

.gridItem:nth-child(4) {
    grid-row-start: 3;
    grid-row-end: 4;
    grid-column-start: 3;
    grid-column-end: 4;
}

.sectionContainer {
    margin-top: 2.5rem;
}

@media (max-width: var(--ark-large-mobile-768)) {
    .heading {
        font-size: var(--ark-16-font-size) !important;
        line-height: var(--ark-22-line-height) !important;
        margin-bottom: 0 !important;
    }

    .sectionContainer {
        margin-top: 1rem;
    }

    .container {
        margin-bottom: 1.125rem;
    }
}
