@import '../../../variables.css';

.adContainer {
    padding: 0 1.25rem 1.25rem;
    border-radius: 1.25rem;
    text-align: center;
    visibility: visible;
}

.adContainer.hasBackground {
    /*background-color: var(--ark-gray-extra-light-color);*/
}

.adContainer.horizontalAd {
    padding-left: 0;
    padding-right: 0;
}

.adContainer.adContainerBackgroundLight {
    background-color: var(--ark-gray-extra-light-color);
}

.adContainer.adContainerBackgroundDark {
   /* background-color: var(--ark-neutral-black-30);*/
    padding-top: 0px;
}

.adContainer_300x250 {
    width: 300px;
    height: 250px;
}

.adContainer_250x250 {
    width: 250px;
    height: 250px;
}

.adContainer_336x280 {
    width: 336px;
    height: 280px;
}

.adContainer_300x600 {
    width: 300px;
    height: 600px;
}

.adContainer_728x90 {
    display: flex;
    justify-content: center;
    width: 728px;
    height: 90px;
}

.adContainer_970x90 {
    display: flex;
    justify-content: center;
    width: 970px;
    height: 90px;
}

.adContainer_970x250 {
    display: flex;
    justify-content: center;
    width: 970px;
    height: 250px;
}

.adContainer_320x50 {
    width: 320px;
    height: 50px;
}

.adContainer_320x100 {
    width: 320px;
    height: 100px;
}

.adContainer_468x60 {
    display: flex;
    justify-content: center;
    width: 468px;
    height: 60px;
}

.adContainer_160x600 {
    width: 160px;
    height: 600px;
}

.caption {
    font-size: var(--ark-16-font-size);
    line-height: var(--ark-22-line-height);
    font-weight: var(--ark-font-weight-regular);
    color: var(--ark-gray-medium-color);
    margin: 0;
}

.caption.captionLight {
    color: var(--ark-gray-medium-color);
}

.caption.captionDark {
    color: var(--ark-neutral-white);
}

.caption.captionAlignLeft {
    display: flex;
    justify-content: flex-start;
}

.caption.captionAlignCenter {
    display: flex;
    justify-content: center;
}

.caption.captionAlignRight {
    display: flex;
    justify-content: flex-end;
}

.caption.hideCaption {
    display: none;
}

.adSkeleton {
    background-color: var(--ark-gray-light2-color);
    width: 100%;
}

.adSkeleton.wideSkeleton {
    height: 90px;
}

@media (min-width: var(--ark-large-desktop-1920)) {
    .adContainer.disabledContainer {
        padding: 0;
        border-radius: unset;
    }
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .adContainer {
        padding-left: 0;
        padding-right: 0;
        margin: 0 auto;
    }
}
