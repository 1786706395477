@import '../../../variables.css';

.dropDownHandler {
    cursor: pointer;
    font-weight: var(--ark-font-weight-bold);
    width: fit-content;
    color: var(--ark-neutral-white);
}

.dropDownHandler:hover,
.dropDownHandler:hover .dropdownArrow {
    color: var(--ark-main-cyan-figma);
}

.dropDownHandlerActive,
.dropDownHandlerActive .dropdownArrow {
    color: var(--ark-main-blue-figma) !important;
}

.dropDownBlock {
    position: absolute;
    overflow: hidden;
    width: 11rem;
    max-height: 0;
    transition: max-height 0.2s ease-out;
    background: var(--ark-primary-white-figma);
    border-radius: 0.625rem;
}

.dropDownBlock.visible {
    max-height: 13rem;
}

.dropDownItems {
    overflow-y: auto;
    max-height: 13rem;
}

.dropDownItems .dropDownItem {
    padding-left: 0.5rem;
    outline-offset: calc(var(--focus-state-outline-width) * -1);
    padding-bottom: 0;
    height: 3rem;
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: rgba(35, 31, 32, 1);
    cursor: pointer;
    display: flex;
    align-items: center;
}

.dropDownItem:hover {
    background: var(--ark-primary-black-20-figma);
}

.dropdownArrow {
    vertical-align: middle;
    color: var(--ark-neutral-white);
    transform: rotate(180deg);
    transition: transform 0.2s ease-out;
}

.dropdownArrow.arrowUp {
    transform: rotate(0deg);
}

/* Scrollbar Styling */
.dropDownContainer ::-webkit-scrollbar {
    width: 8px;
}

.dropDownContainer ::-webkit-scrollbar-track {
    background-color: #ebebeb;
    -webkit-border-radius: 3px;
    border-radius: 3px;
}

.dropDownContainer ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 3px;
    border-radius: 3px;
    background: #d9d9d9;
}
