@import "../../../../../../../../variables.css";

.container {
    padding: 36px 24px;
    background-color: rgba(11, 16, 61, 0.9);
    border-radius: 10px;
    color: var(--ark-secondary-solid-lightgrey-figma);
    gap: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.list {
    font-weight: var(--ark-font-weight-extraBold);
    font-size: var(--ark-16-font-size);
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.list li {
    position: relative;
    line-height: 28px;
}

.restart .list li::before {
    background: var(--ark-primary-red);
}

.listContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

/* - 1359 */
@media (max-width: var(--ark-medium-desktop-figma-below-1359)) {
    .list {
        font-size: var(--ark-16-font-size);
        gap: 4px;
    }

    .title {
        font-size: var(--ark-18-font-size);
    }

    .container {
        padding: 24px 16px;
        gap: 16px;
        align-items: center;
    }

    .list li::before {
        top: 5px;
    }
}

/* - 1023 */
@media (max-width: var(--ark-small-desktop-figma-below-1023)) {
    .container {
        padding: 16px;
        align-items: flex-start;
    }
}

/* - 599 */
@media (max-width: var(--ark-small-mobile-figma-below-599)) {
    .list {
        font-size: var(--ark-14-font-size);
    }
}
