@import '../../../../../variables.css';

.formH4 {
    font-family: var(--ark-font-family);
    font-weight: var(--ark-font-weight-bold);
    font-size: var(--ark-22-font-size);
    line-height: var(--ark-32-line-height);
    color: var(--ark-black-color);
    margin-bottom: 1.5rem;
}

.form {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: auto 0 auto 1rem auto 1rem auto 1rem auto 1rem auto 1rem auto 1.5rem auto 2rem;
    margin-right: 2.5rem;
    width: 21.75rem;
}

.formField input,
.formField textarea {
    border-color: var(--ark-neutral-black);
}

.formField input::placeholder,
.formField textarea::placeholder {
    color: var(--ark-neutral-disabled);
}

.formField:nth-child(1) {
    grid-row: 1/2;
}

.formField:nth-child(2) {
    grid-row: 3/4;
}

.formField:nth-child(3) {
    grid-row: 5/6;
}

.formField:nth-child(4) {
    grid-row: 7/8;
}

.formField:nth-child(5) {
    grid-row: 9/10;
}

.formField:nth-child(6) {
    grid-row: 11/12;
}

.formField:nth-child(7) {
    grid-row: 13/14;
}

.formField:nth-child(8) {
    grid-row: 15/16;
}

.formField:nth-child(9) {
    grid-row: 17/18;
}

.formField h2, .formField ul {
    margin: 0 10px;
}

.formLabel {
    line-height: 1.22;
    font-size: 1.125rem;
}

.formCheckboxLabel {
    line-height: 1.13;
    margin-left: 0;
}

.descriptionText {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: normal;
    color: rgb(0, 0, 0);
    flex-grow: 1;
    height: 10rem;
    width: 100%;
    box-sizing: border-box;
    border-radius: 0.625rem;
    box-shadow: none;
    border: 1px solid rgba(155, 157, 163, 1);
    padding-top: 0.8125rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-bottom: 0.8125rem;
}

.descriptionText.frFix {
    padding-top: 1.5rem;
}

.descriptionText:focus {
    background-color: #fff;
}

.buttons {
    display: flex;
    justify-content: space-between;
}

.formButton {
    width: 9.375rem !important;
}

.hidden {
    visibility: hidden;
}

.relatedArticle {
    font-size: var(--ark-24-font-size);
    font-weight: normal;
    line-height: var(--ark-h3-line-height);
    text-decoration: none;
}

.closeButton {
    background: none;
    border: none;
    position: absolute;
    right: 1.25rem;
    top: 1.25rem;
    padding: 0;
}

.question {
    font-family: var(--ark-font-family);
    font-weight: var(--ark-font-weight-bold);
    font-size: 1rem;
    line-height: 1.8rem;
    color: var(--ark-tertiary-color);
    cursor: pointer;
    transition: color 0.166s linear;
    padding: 0 0 1rem 1rem;
    text-align: left;
}

.relatedArticlesTitle {
    font-size: var(--ark-24-font-size);
    font-weight: var(--ark-font-weight-bold);
    color: var(--ark-black-color);
    padding-bottom: 0.5rem;
}

.question:hover {
    color: var(--ark-primary-blue-figma);
}

.dropZoneOverlay {
    position: absolute;
    background-color: var(--ark-neutral-white-80);
    width: calc(100% - 6rem);
    height: 514px;
    margin-top: 0;
    justify-content: center;
    text-align: center;
    align-items: center;
    z-index: 1;
    display: none;
    font-size: 1.125rem;
    line-height: 2rem;
    border-radius: 10px;
    border: dashed 2px var(--ark-primary-blue-figma);
}

.dropZoneOverlay.rejectedDrop {
    border: dashed 2px var(--ark-arkadium-red-color);
}

.dropZoneOverlay p {
    color: var(--ark-primary-blue-figma);
    font-weight: var(--ark-font-weight-bold);
}

.dropZoneOverlay p.replaceFile {
    font-weight: var(--ark-font-weight-regular);
}

.dropZoneOverlay p.rejectedDropText {
    color: var(--ark-arkadium-red-color);
}

.dropZoneOverlay.visible {
    display: flex;
}

.dropZoneOverlay.height {
    height: 30px;
}

.dropareaSmall {
    height: 85px;
    font-size: 1.125rem;
    line-height: 2rem;
    border: dashed 2px rgba(155, 157, 163, 0.4);
    border-radius: 0.625rem;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
}

.dropareaSmall.withFiles {
    border: dashed 2px rgba(1, 163, 108, 0.5);
}

.dropareaSmall.errorBorder {
    border: dashed 2px var(--ark-arkadium-red-color);
}

.dropareaSmall.acceptedFilesBorder {
    border: dashed 2px var(--ark-secondary-color);
}

.tooltipBlock {
    position: absolute;
    right: 0;
    top: calc(50% - 17px);
}

.tooltipAttachBlock {
    position: absolute;
    right: 30px;
    top: calc(50% - 17px);
}

.dropareaSmall p {
    font-weight: bold;
    padding: var(--ark-spacing-xxs) 2.125rem 0 var(--ark-spacing-s);
    line-height: normal;
}

.hideError {
    display: none;
}

.rejectedUploadText p {
    color: var(--ark-arkadium-red-color);
}

.rejectedUploadText {
    font-size: var(--ark-14-font-size);
}

.maxSizeText {
    color: var(--ark-black-color);
    font-weight: normal;
}

.attachedFile {
    background-color: var(--ark-gray-extra-light-color);
    color: var(--ark-gray-medium-color);
    font-size: 1.125rem;
    font-weight: var(--ark-font-weight-bold);
    padding: 1rem 1rem 1rem 3.125rem;
    border-radius: 0.625rem;
    margin-left: 2rem;
    position: relative;
    max-width: calc(100% - 6rem);
    width: fit-content;
    display: flex;
}

.attachedFile::before {
    content: ' ';
    background-image: url(./icons/form/picture-icon.svg);
    height: 32px;
    width: 32px;
    position: absolute;
    left: 10px;
}

.attachmentWrapper {
    width: 100%;
}

.attachedFileCloseBtn {
    position: absolute;
    background-color: transparent;
    right: -15px;
    top: -15px;
}

.closeIcon {
    height: 34px;
    width: 34px;
    cursor: pointer;
    border-radius: 50%;
    background-color: var(--ark-neutral-light);
    color: var(--ark-black-color);
}

.closeIcon:hover {
    color: var(--ark-primary-blue-figma);
}

.appLoader {
    display: none;
}

.appLoader.showLoader {
    display: flex;
}

.attachedFileName {
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-align: left;
    color: var(--ark-black-color);
}

.formCheckbox {
    color: var(--ark-primary-blue-figma);
    width: 64px;
}

.indicatorDescription {
    margin-top: var(--ark-spacing-xxs);
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .formWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1.5rem 0 3rem 0;
        position: relative;
    }
    .extendMediaQuery .form {
        padding: 0 1.25rem;
        margin: 0;
        max-width: 100%;
    }

    .extendMediaQuery .buttons {
        flex-direction: column;
        align-items: center;
        position: fixed;
        bottom: 3.75rem;
        width: 100%;
        left: 0;
        right: 0;
        z-index: 100000;
        border-top: 1px solid var(--ark-gray-light-color);
        background: var(--ark-neutral-white);
        padding: 0.5rem 0 0;
        height: 3.875rem;
    }

    .extendMediaQuery .formButton {
        margin-top: 0;
        height: 3rem;
    }

    .extendMediaQuery .formButton:nth-child(1) {
        display: none;
    }

    .extendMediaQuery .formH4 {
        margin-top: 0;
        padding-left: 1.25rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        margin-bottom: 0;
        font-size: var(--ark-20-font-size) !important;
        line-height: var(--ark-22-line-height) !important;
        width: 21.75rem;
        max-width: 100%;
    }

    .extendMediaQuery .formCheckbox {
        width: 4.375rem;
        height: 2.125rem;
    }

    .attachIcon {
        right: 8px;
        top: 40px;
    }

    .attachedFile,
    .rejectedUploadText {
        line-height: 1.2rem;
    }

    .rejectedUploadText {
        padding-left: 1.0625rem;
        padding-right: 2.1875rem;
    }

    .attachedFile {
        max-width: calc(100% - 4rem);
    }

    .attachedFile::before {
        top: 10px;
    }
}

@media (max-width: var(--ark-small-desktop-figma-1024)) and (orientation: landscape) {
    .extendMediaQuery .buttons {
        padding-left: 1.25rem;
        bottom: 0;
        padding-bottom: 0.5rem;
    }

    .extendMediaQuery .form {
        padding-bottom: 4rem;
    }
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .form {
        padding: 0 1.25rem;
        margin: 0;
        max-width: 100%;
        grid-template-rows: auto 1rem auto 1rem auto 1rem auto 1rem auto 1rem auto 1rem auto 1rem;
    }

    .buttons {
        padding: 0;
        flex-direction: column;
        align-items: center;
        position: fixed;
        bottom: 3.75rem;
        width: 100%;
        left: 0;
        right: 0;
        z-index: 100000;
        border-top: 1px solid var(--ark-gray-light-color);
        background: var(--ark-neutral-white);
        padding-top: 0.5rem;
        height: 3.875rem;
    }

    .formButton {
        margin-top: 0;
        height: 3rem;
    }

    .formButton:nth-child(1) {
        display: none;
    }

    .formH4 {
        margin-top: 0;
        height: 2.5rem;
        display: flex;
        align-items: center;
        padding-left: 1.25rem;
        margin-bottom: 0;
        font-size: var(--ark-20-font-size) !important;
        line-height: var(--ark-22-line-height) !important;
        width: 21.75rem;
        max-width: 100%;
    }

    .formCheckboxLabel {
        max-width: 29.3125rem;
        font-size: var(--ark-14-font-size) !important;
        font-weight: var(--ark-font-weight-regular) !important;
        line-height: var(--ark-label1-line-height) !important;
    }

    .tooltipBlock {
        right: initial;
        left: 0;
    }
}

@media (max-width: var(--ark-small-desktop-figma-1024)) and (orientation: landscape) {
    .buttons {
        padding-left: 1.25rem;
        bottom: 0;
        padding-bottom: 0.5rem;
    }

    .form {
        padding-bottom: 4.5rem;
    }
}

@media (max-width: var(--ark-small-mobile-figma-below-599)) and (orientation: portrait) {
    .form {
        padding-bottom: 4.5rem;
    }
}
