@import "../../../../variables.css";

.buttonWithIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--ark-neutral-white);
    border: 2px solid transparent;
    border-radius: 1.25rem;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    min-height: 3rem;
    width: 100%;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.icon {
    position: relative;
    margin-left: 1rem;
    width: 2.125rem;
    height: 2.125rem;
    color: var(--ark-neutral-white);
    z-index: 100
}

.buttonWithIcon .caption {
    font-size: var(--ark-20-font-size);
    line-height: var(--ark-22-line-height);
    text-align: center;
    font-weight: var(--ark-font-weight-bold);
    color: var(--ark-neutral-white);
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    z-index: 102;
}

.buttonWithIcon::before {
    content: ' ';
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    transform: translateX(-100%);
    transition: transform .384s var(--ark-ease-in-out-sine);
}

.buttonWithIcon::after {
    content: ' ';
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    transform: translateX(-100%);
    transition: transform .384s var(--ark-ease-in-out-sine);
}

.buttonWithIcon:hover::before {
    transform: translateX(0);
    z-index: 1;
}

.buttonWithIcon:hover::after {
    z-index: 0;
}

.buttonWithIcon.pressedState::before {
    z-index: 0;
}

.buttonWithIcon.pressedState::after {
    z-index: 1;
    transform: translateX(0);
}

.buttonWithIcon.wrapLines .caption {
    white-space: normal;
}

.buttonWithIcon[disabled] {
    border-color: transparent;
    background-color: var(--ark-gray-medium-color);
    cursor: default;
}

.buttonWithIcon:not([disabled]):hover .caption  {
    color: var(--ark-neutral-white);
}

.buttonWithIcon.primary {
    background-color: var(--ark-primary-blue-figma);
    border-color: var(--ark-primary-blue-figma);
}

.buttonWithIcon.primary:not([disabled]):hover  {
    border-color: var(--ark-primary-dark-color);
}

.buttonWithIcon.primary::before {
    background-color: var(--ark-primary-dark-color);
}

.buttonWithIcon.primary::after {
    background-color: var(--ark-primary-blue-figma);
}

.buttonWithIcon.tertiary {
    background-color: var(--ark-tertiary-color);
    border-color: var(--ark-tertiary-color);
}

.buttonWithIcon.tertiary:not([disabled]):hover  {
    border-color: var(--ark-tertiary-light-color);
}

.buttonWithIcon.tertiary::before {
    background-color:var(--ark-tertiary-light-color);
}

.buttonWithIcon.tertiary::after {
    background-color: var(--ark-tertiary-color);
}

.buttonWithIcon.secondary {
    background-color: var(--ark-secondary-color);
    border-color: var(--ark-secondary-color);
}

.buttonWithIcon.secondary:not([disabled]):hover  {
    border-color: var(--ark-secondary-dark-color);
}

.buttonWithIcon.secondary::before {
    background-color: var(--ark-secondary-dark-color);
}

.buttonWithIcon.secondary::after {
    background-color: var(--ark-secondary-color);
}

.buttonWithIcon.transparent {
    background-color: var(--ark-black-15-color);
    border-color: var(--ark-primary-white-30-figma);
}

.buttonWithIcon.transparent:not([disabled]):hover  {
    border-color: var(--ark-white-50-color);
}

.buttonWithIcon.transparent::before {
    background-color: var(--ark-tertiary-light-color);
}

.buttonWithIcon.transparent::after {
    background-color: var(--ark-black-15-color);
}
