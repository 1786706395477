@import '../../../variables.css';

.container {
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 9999;
    width: calc(100% - 40px);
    min-height: 160px;
    display: grid;
    grid-template-columns: 55% 1fr;
    align-items: center;
    column-gap: 32px;
    padding: 26px 76px 26px 42px;
    border-radius: 16px;
    color: var(--ark-primary-black-figma);
    background-color: var(--ark-primary-white-figma);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    line-height: 1.4;
}

.container.dark {
    color: var(--ark-primary-white-figma);
    background-color: var(--ark-neutral-dark);
}

.container.noEmail {
    display: flex;
    align-items: center;
    justify-content: center;
}

.container.noEmail>*:first-child {
    width: 55%;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.content h2 {
    font-weight: var(--ark-font-weight-bold);
    font-size: var(--ark-28-font-size);
}

.content p {
    font-weight: var(--ark-font-regular-bolder);
    font-size: var(--ark-18-font-size);
}

.content>p:last-child {
    font-size: var(--ark-14-font-size);
}

.bannerForm {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto;
    align-items: center;
    column-gap: 16px;
    row-gap: 4px;
}

.emailContainer {
    position: relative;
    height: 58px;
    display: flex;
    align-items: center;
}

.emailContainer .mailIcon {
    position: absolute;
    z-index: 1;
    width: 24px;
    height: 24px;
    margin-left: 16px;
}

.emailContainer input {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--ark-neutral-white-60);
    border-radius: 10px;
    border: 3px solid transparent;
    padding: 10px 50px;
    color: var(--ark-primary-black-figma);
    font-size: 18px;
}

.emailContainer input::placeholder {
    color: var(--ark-primary-black-figma);
}

.emailContainer input:focus {
    padding-top: 20px;
}

.emailContainer input:focus,
.emailContainer input:active {
    border-color: var(--ark-primary-blue-figma);
}

.inputActiveLabel {
    display: none;
    position: absolute;
    top: 8px;
    left: 54px;
    font-size: var(--ark-12-font-size);
    font-weight: var(--ark-font-weight-bold);
    color: var(--ark-primary-asbestos-figma);
}

.emailContainer input:focus~.inputActiveLabel {
    display: initial;
}

.emailContainer input:not(:placeholder-shown):invalid {
    border-color: var(--ark-hover-dark-red-figma);
}

.emailContainer input:valid {
    border-color: var(--ark-extended-semantic-success);
}

.emailContainer>*:last-child {
    position: absolute;
    right: 16px;
    z-index: 1;
    width: 24px;
    height: 24px;
}

.error {
    display: flex;
    gap: 8px;
    margin-bottom: 10px;
    font-size: var(--ark-14-font-size);
    color: var(--ark-hover-dark-red-figma);
    grid-row: 2;
}

.errorIcon {
    width: 14px;
    height: 14px;
}

.errorIcon>path {
    fill: var(--ark-hover-dark-red-figma);
}

.checkboxContainer {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    gap: 16px;
    font-size: var(--ark-14-font-size);
    grid-row: 3;
}

.checkbox {
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    width: 32px;
    height: 32px;
    background: none;
    border: 3px solid var(--ark-primary-asbestos-figma);
    outline: 3px solid transparent;
    border-radius: 8px;
}

.checkbox:not(:checked):hover {
    background: rgb(172, 171, 171, 0.2);
    outline-color: rgb(172, 171, 171, 0.2);
}

.checkbox:checked {
    background: var(--ark-primary-blue-figma);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='14' viewBox='0 0 20 14' fill='none'%3E%3Cpath d='M18.9469 0.613392C18.823 0.488421 18.6755 0.389228 18.513 0.321536C18.3505 0.253845 18.1763 0.218994 18.0003 0.218994C17.8242 0.218994 17.65 0.253845 17.4875 0.321536C17.325 0.389228 17.1775 0.488421 17.0536 0.613392L7.12026 10.5601L2.94693 6.37339C2.81823 6.24907 2.66631 6.15132 2.49983 6.08572C2.33336 6.02011 2.1556 5.98794 1.97669 5.99103C1.79778 5.99413 1.62123 6.03243 1.45713 6.10376C1.29303 6.17508 1.14458 6.27803 1.02026 6.40673C0.89594 6.53542 0.798188 6.68734 0.732583 6.85382C0.666978 7.02029 0.634804 7.19805 0.637899 7.37696C0.640994 7.55587 0.679298 7.73242 0.750623 7.89652C0.821948 8.06062 0.924896 8.20907 1.05359 8.33339L6.17359 13.4534C6.29754 13.5784 6.44501 13.6776 6.60749 13.7452C6.76997 13.8129 6.94424 13.8478 7.12026 13.8478C7.29628 13.8478 7.47055 13.8129 7.63303 13.7452C7.79551 13.6776 7.94298 13.5784 8.06693 13.4534L18.9469 2.57339C19.0823 2.44854 19.1903 2.297 19.2642 2.12833C19.338 1.95967 19.3762 1.77753 19.3762 1.59339C19.3762 1.40926 19.338 1.22712 19.2642 1.05845C19.1903 0.889784 19.0823 0.738248 18.9469 0.613392Z' fill='%23F9F9F9'/%3E%3C/svg%3E");
    background-size: 18px;
    background-repeat: no-repeat;
    background-position: center;
    border-color: var(--ark-hover-dark-blue-figma);
    border-radius: 8px;
}

.checkbox:checked:hover {
    outline-color: rgba(0, 66, 187, 0.2);
}

.checkboxContainer>span {
    width: fit-content;
    flex-shrink: 2;
}

.cta {
    font-weight: var(--ark-font-weight-bold);
    font-size: 20px;
    line-height: inherit;
    text-align: center;
    color: var(--ark-primary-white-figma);
    background: var(--ark-main-green-figma);
    border: none;
    border-radius: 24px;
    padding: 10px 30px;
}

.cta:disabled {
    cursor: not-allowed;
    background: var(--ark-secondary-solid-lightgrey-figma);
    color: var(--ark-secondary-solid-midgrey);
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 0;
    background: none;
    border: none;
}

.container.dark .close {
    color: var(--ark-primary-white-figma);
}

@media only screen and (max-width: var(--ark-medium-desktop-figma-1360)) {
    .container {
        padding: 40px 42px 26px 42px;
        grid-template-columns: 1fr;
        row-gap: 20px;
    }

    .container.noEmail>*:first-child {
        width: 80%;
    }
}

@media only screen and (max-width: var(--ark-medium-mobile-figma-768)) {

    .container {
        z-index: 999999999;
        width: calc(100% - 24px);
        left: 50%;
        padding: 40px 16px 26px 16px;
        transform: translate(-50%);
        max-height: 82vh;
        overflow-y: hidden;
    }

    .content h2 {
        font-weight: var(--ark-font-weight-bold);
        font-size: var(--ark-20-font-size);
    }

    .content p {
        font-weight: var(--ark-font-regular-bolder);
        font-size: var(--ark-16-font-size);
    }

    .bannerForm {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto;
    }

    .cta {
        margin: auto;
        grid-row: -1;
    }

    .mobileOverlay {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999999998;
        background: rgba(35, 31, 32, 0.2);
        width: 100vw;
        height: 100vh;
    }
}

@media (min-width: 566px) and (max-width: var(--ark-small-desktop-figma-1024)) and (orientation: landscape) {
    .container {
        width: calc(100% - 5.625rem - 40px) !important;
        left: calc(5.625rem + 20px) !important;
        transform: none !important;
    }
}

@media only screen and (max-width: var(--ark-small-desktop-figma-1024)) {
    .container.noEmail {
        flex-direction: column;
    }

    .container.noEmail>*:first-child {
        width: 100%;
    }
}