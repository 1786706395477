@import "../../../../../../variables.css";

.container {
    padding: 36px 24px;
    background-color:  var(--ark-primary-royal-blue-figma-70);
    border-radius: 10px;
    color: var(--ark-secondary-solid-lightgrey-figma);
    gap:24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.title {
    font-weight: var(--ark-font-weight-bold);
    font-size: var(--ark-24-font-size);
    line-height: var(--ark-28-line-height);
}

.list {
    font-weight: var(--ark-font-weight-bold);
    font-size: var(--ark-18-font-size);
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.list li {
    padding-left: 23px;
    position: relative;
    line-height: 28px;
}

.list li::before {
    content: ' ';
    width: 16px;
    height: 16px;
    border-radius: 16px;
    position: absolute;
    background: linear-gradient(314.74deg, #C28C2B 12.84%, #E6C14E 48.8%, #C28C2B 84.98%);
    left: -2px;
    top: 6px;
}

.restart .list li::before {
    background: var(--ark-primary-red);
}

/* - 1359 */
@media (max-width: var(--ark-medium-desktop-figma-below-1359)) {
    .list {
        font-size: var(--ark-16-font-size);
        gap: 4px;
    }

    .title {
        font-size: var(--ark-18-font-size);
    }

    .container {
        padding: 24px 16px;
        gap: 16px;
        align-items: center;
    }

    .list li::before {
        top: 5px;
    }
}

/* - 1023 */
@media (max-width: var(--ark-small-desktop-figma-below-1023)) {
    .container {
        padding: 16px;
    }
}

/* - 599 */
@media (max-width: var(--ark-small-mobile-figma-below-599)) {
    .list {
        font-size: var(--ark-14-font-size);
    }
}