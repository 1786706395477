@import '../../../../../variables.css';

.tabContent {
    color: var(--ark-neutral-dark);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    padding: 50px 0;
}

.tabContent a {
    text-decoration: none;
}

.header {
    font-size: var(--ark-28-font-size);
    line-height: var(--ark-24-line-height);
    font-weight: var(--ark-font-weight-light);
    margin-bottom: 1.25rem;
}

.subHeader {
    font-size: var(--ark-18-font-size);
    line-height: var(--ark-24-line-height);
    font-weight: var(--ark-font-weight-regular);
    margin-bottom: 1.875rem;
    max-width: 430px;
    text-align: center;
}

.subHeader b {
    font-weight: bold;
}

.subHeader p {
    text-align: center;
    font-weight: var(--ark-font-weight-bold);
}

.inputWrapper {
    margin-bottom: 1.25rem;
    position: relative;
}

.input {
    width: 20rem;
    height: 3rem;
}

.policy a {
    color: var(--ark-primary-blue-figma);
    font-size: var(--ark-18-font-size);
    line-height: var(--ark-24-font-size);
    text-align: center;
}

.forgotPassword {
    margin-bottom: 1.25rem;
}

.submitButton {
    max-width: 127px;
    border-radius: 1.5rem;
    padding: 10px 30px;
    height: 3rem;
}

.dividerBlock {
    margin: 2.5rem 0;
}

.socialButtons {
    display: flex;
    margin-bottom: 3.75rem;
}

.validation {
    position: relative;
    bottom: 0;
}

.validationMessage {
    font-size: var(--ark-14-font-size);
    line-height: var(--ark-16-line-height);
    color: var(--ark-primary-asbestos-figma);
    margin-top: 0.25rem;
    margin-left: 6px;
}

.validationMessageError .dot {
    background-color: var(--ark-primary-red);
}

.validationMessageOk .dot {
    background-color: var(--ark-primary-green);
}

.validationMessageError {
    color: var(--ark-primary-red);
}

.validationMessageOk {
    color: var(--ark-primary-green);
}

.dot {
    height: 0.5rem;
    width: 0.5rem;
    margin-right: 0.5rem;
    background-color: var(--ark-primary-asbestos-figma);
    border-radius: 50%;
    display: inline-block;
}

.checkboxContainer {
    max-width: 20rem;
    margin: 0 auto 2.5rem;
}

.checkbox {
    min-width: 2.125rem;
    height: 2.125rem;
    color: var(--ark-primary-blue-figma-font);
}

.checkboxLabel {
    font-size: var(--ark-14-font-size);
    line-height: var(--ark-18-line-height);
    color: var(--ark-primary-black-figma);
    font-weight: var(--ark-font-weight-regular);
    margin-left: var(--ark-spacing-xxs);
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .tabContent {
        padding: 0;
    }
}
