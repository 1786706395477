@import '../../../../../variables.css';

.container {
    padding-top: 40px;
    padding-bottom: 140px;
    padding-left: 62px;
    padding-right: 62px;

    grid-gap: 24px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    max-width: 1130px;
    align-items: stretch;
}

.loader {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    z-index: 10;
    align-items: center;
}

.card {
    flex: 0 0 530px;
}

.icons {
    width: 100%;
}

.textInfo {
    color: var(--ark-primary-white-figma);
    font-size: var(--ark-20-font-size);
    line-height: var(--ark-24-line-height);
    text-align: center;
    max-width: 635px;
    margin: 0 auto;
}

.faq {
    width: 100%;
}

.stats {
    height: 240px;
    flex-grow: 1;
}

.status {
    flex: 0 0 530px;
}

.history {
    flex-basis: calc(100% - 556px);
}

.restart .history {
    flex: 1 1 auto;
    margin: 0 auto;
    max-width: 867px;
}

.link {
    color: var(--ark-main-cyan-figma);
    text-decoration: underline;
}

/* 1024 + */
@media (min-width: var(--ark-small-desktop-figma-1024)) {
    .container {
        max-width: 1130px;
    }
}

/* 1359 */
@media (max-width: var(--ark-medium-desktop-figma-below-1359)) {
    .container:not(.none) {
        max-width: 532px;
        padding-left: 0;
        padding-right: 0;
    }

    .stats {
        height: 100%;
    }

    .textInfo {
        font-size: var(--ark-18-font-size);
        line-height: var(--ark-24-line-height);
        max-width: 480px;
    }

    .container > div {
        flex-basis: 100%;
    }
}

/* - 1023 */
@media (max-width: var(--ark-small-desktop-figma-below-1023)){
    .container {
        padding-top: 24px;
        padding-bottom: 64px;
    }

    .textInfo {
        font-size: var(--ark-14-font-size);
        line-height: var(--ark-16-line-height);
    }
}

/* - 719 */
@media (max-width: var(--ark-medium-mobile-figma-below-719)) {
    .container {
        padding-top: 24px;
        padding-bottom: 40px;
        gap: 16px;
    }
}

/* 599 */
@media (max-width:var(--ark-small-mobile-figma-below-599)) {
    .container:not(.none) {
        gap: 16px;
        padding: 16px 16px 40px;
    }

    .container {
        padding-left: 16px;
        padding-right: 16px;
    }

    .card {
        max-width: 288px;
        margin: 0 auto;
    }
}
