@import '../../../variables.css';

.gemErrorContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--ark-spacing-s);
    text-align: center;
    background-color: var(--ark-primary-royal-blue-figma);
}

.image {
    max-height: 250px;
}

.message {
    color: var(--ark-neutral-white);
    font-weight: var(--ark-font-weight-bold);
    font-size: var(--ark-28-font-size);
    line-height: var(--ark-39-line-height);
    margin-bottom: var(--ark-spacing-s);
}

.gemErrorContainer>button {
    padding: 10px 30px;
}

.gemErrorContainer>button:last-child {
    background-color: unset;
}