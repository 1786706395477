@import '../../../../../../variables.css';

.loggedInView {
    padding: var(--ark-spacing-xxl-5) var(--ark-spacing-xxl) var(--ark-spacing-xxl-7);
    background-color: inherit;
    background-image: url(./illustrations/gems-shop/plus_gems_shop_background.webp);
    background-size: cover;
    background-repeat: no-repeat;
}

.notLoggedInView {
    background-color: var(--ark-neutral-white);
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .loggedInView {
        background-image: url(./illustrations/gems-shop/plus_gems_shop_background_mobile.png);
        padding: var(--ark-spacing-xxl-5) 0 5.625rem;
    }
}

@media (max-width: 926px) {
    .loggedInView {
        padding: var(--ark-spacing-xxl-5) var(--ark-spacing-xxl) var(--ark-spacing-xxl-7);
    }
}

/* width: 0 - 599 */
@media (max-width: var(--ark-small-mobile-figma-below-599)) {
    .loggedInView {
        padding: var(--ark-spacing-s) var(--ark-spacing-s) var(--ark-spacing-xxl-7);
    }
}
