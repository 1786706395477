@import '../../../variables.css';

.container {
    position: relative;
}

.checkbox {
    width: 100%;
    display: flex;
    cursor: pointer;
    text-align: left;
}

.label {
    margin-left: 0.375rem;
    font-size: var(--ark-18-font-size);
    font-weight: var(--ark-font-weight-regular);
    line-height: var(--ark-22-line-height);
    font-size: var(--ark-16-font-size);
    color: var(--ark-black-color);
}

.errorText {
    margin: 0;
    margin-left: 0.5rem;
    display: block;
    font-size: var(--ark-12-font-size);
    font-weight: var(--ark-font-weight-regular);
    line-height: var(--ark-14-line-height);
    color: var(--ark-arkadium-red-color);
}

.warningIcon {
    width: 2.125rem;
    height: 2.125rem;
    position: absolute;
    right: -1.56rem;
    top: 40%;
    transform: translateY(-50%);
}

.iconClassName {
    color: var(--ark-primary-blue-figma);
}
