@import '../../../../../../variables.css';

.topBlock {
    height: 334px;
    background-image: url(./illustrations/subscription/plus_background.webp);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
}

.advantagesContainer {
    height: 100%;
    background-image: url('./illustrations/subscription/plus_container_background.webp');
    background-repeat: no-repeat;
    background-size: cover;
}

.mainInfo {
    display: flex;
    height: 120px;
    align-items: center;
    margin-top: 67px;
}

.advantageLabel {
    color: var(--ark-advantage-yellow-figma);
    font-weight: var(--ark-font-weight-bold);
}

.rightSide {
    margin: 45px 0 0 50px;
    width: 340px;
}

.aboutSubscription {
    font-size: var(--ark-12-font-size);
    line-height: var(--ark-24-line-height);
    font-weight: 300;
    text-align: center;
    color: var(--ark-neutral-white);
    margin: 0 118px;
}

.advantagesRow {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    column-gap: 16px;
    min-height: 15.25rem; /* for loader */
    padding-top: 24px;
}

.aboutRow {
    margin-top: 22px;
    padding-bottom: 40px;
}

.saveStar {
    position: absolute;
    top: -5px;
    right: -8px;
}

.subscriptionLinks {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    color: var(--ark-neutral-white);
    font-size: 16px;
    padding-top: 56px;
    min-height: 150px;
}

.subscriptionLinks a {
    color: var(--ark-neutral-white);
    font-weight: 700;
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .aboutSubscription {
        margin: 0 auto;
        max-width: 516px;
        text-align: center;
    }
}

@media (max-width: 926px) {
    .aboutSubscription {
        margin: 0;
        max-width: unset;
        padding: 0 15px;
    }
}

/*****Media *******/
@media (max-width: var(--ark-small-mobile-figma-below-599)) {
    .topBlock {
        height: 358px;
        flex-direction: column;
        justify-content: start;
    }

    .mainInfo {
        margin-top: 16px;
        flex-direction: column;
        height: inherit;
    }

    .leftSide svg {
        height: 58px;
        width: 120px;
    }

    .rightSide {
        display: flex;
        justify-content: flex-start;
        margin: 0;
        width: 100%;
        padding-left: 36px;
    }

    .advantagesRow {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        min-height: 24rem; /* for loader */
        row-gap: 16px;
    }

    .aboutSubscription {
        width: 100%;
        margin: 0;
        font-size: var(--ark-16-font-size);
        line-height: var(--ark-20-line-height);
        padding: 20px;
    }

    .aboutRow {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .subscriptionLinks {
        height: 150px;
        flex-wrap: wrap;
        padding: 20px 0;
    }
}
