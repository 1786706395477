@import "../../../../../../../../variables.css";

.container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 48px;
    padding: 48px;
    background-color: rgba(11, 16, 61, 0.7);
    border-radius: var(--ark-border-radius);
}

.title {
    font-weight: var(--ark-font-weight-bold);
    font-size: var(--ark-36-font-size);
    line-height: var(--ark-36-line-height);
    color: var(--ark-neutral-white);
    margin-bottom: 12px;
}

.btnSubscribe {
    padding: 10px 20px;
    width: fit-content;
}


.advantagesList {
    color: var(--ark-primary-white-figma);
    font-size: var(--ark-16-font-size);
    font-weight: var(--ark-font-weight-extraBold);
    display: flex;
    flex-direction: column;
}

.listItem {
    display: flex;
    align-items: center;
}

.description {
    display: flex;
    justify-content: space-between;
}

.logo {
    margin-left: auto;
}

.text {
    width: 100%;
}

/* 1359 */
@media (max-width: var(--ark-medium-desktop-figma-below-1359)) {
    .title {
        display: flex;
        justify-content: center;
        font-size: var(--ark-24-font-size);
        line-height: var(--ark-24-line-height);
        margin-bottom: 12px;
    }

    .description {
        column-gap: 10px;
        font-size: var(--ark-14-font-size);
        line-height: var(--ark-18-line-height);
    }

    .listItem span {
        white-space: nowrap;
    }
}

/* 1023 */
@media (max-width: var(--ark-small-desktop-figma-below-1023)) {
    .container {
        gap: 36px;
        padding: 43px 36px;
    }

    .description {
        display: unset;
    }

    .logo {
        margin-left: auto;
    }
}

/* 719 */
@media (max-width: var(--ark-medium-mobile-figma-below-719)) {
    .container {
        gap: 24px;
        padding: 32px 24px;
    }

}

/* 599 */
@media (max-width: var(--ark-small-mobile-figma-below-599)) {
    .avatar {
        order: 1;
        flex: 0 0 110px;
    }

    .text {
        order: 3;
        flex: 0 0 100%;
        align-items: center;
        display: flex;
        flex-direction: column;
    }

    .logo {
        order: 2;
        flex: 0 0 97px;
        align-items: center;
        display: flex;
        margin-left: auto;
        width: 50%;
    }

    .container {
        padding: 24px 16px;
        flex-wrap: wrap;
    }

    .description {
        flex-direction: column;
    }
}
