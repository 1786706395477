@import '../../../../variables.css';

.tabItem {
    padding: var(--ark-spacing-l) var(--ark-spacing-m) var(--ark-spacing-s) var(--ark-spacing-m);
    font-size: var(--ark-24-font-size);
    font-weight: var(--ark-font-weight-bold);
    position: relative;
    margin: 0 0.313rem;
    color: var(--ark-neutral-black);
    border: 0;
    background: transparent;
    line-height: normal;
}

.tabItem:hover {
    color: #7056d6;
}

.tabItem::before {
    content: ' ';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--ark-primary-blue-figma);
    height: 0.375rem;
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition: -webkit-transform 0.332s cubic-bezier(0.37, 0, 0.63, 1);
    transition: -webkit-transform 0.332s cubic-bezier(0.37, 0, 0.63, 1);
    transition: transform 0.332s cubic-bezier(0.37, 0, 0.63, 1), -webkit-transform 0.332s cubic-bezier(0.37, 0, 0.63, 1);
}

.tabItem.active::before {
    -webkit-transform: scale(1);
    transform: scale(1);
}

.tabItem.active {
    color: var(--ark-neutral-black);
}

.gamePageTab {
    cursor: pointer;
}

.categoryPageTab::before {
    background-color: transparent;
}

.profilePageCollectionsTab {
    color: #b9b1c1;
    font-weight: var(--ark-font-weight-regular);
}
.profilePageCollectionsTab.active {
    color: #479f74;
    font-weight: var(--ark-font-weight-bold);
    background-color: #dbfbf1;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.profilePageCollectionsTab:hover {
    color: #479f74;
}
.profilePageCollectionsTab::before {
    background-color: #479f74;
}

/* GEMS SHOP TABS */
.gemsShopTab {
    display: flex;
    align-items: end;
    justify-content: center;
    color: var(--ark-neutral-medium);
    min-height: 4rem;
    font-weight: var(--ark-font-weight-regular);
    margin: 0;
}
.gemsShopTab.active {
    color: var(--ark-extended-semantic-success);
    font-weight: var(--ark-font-weight-bold);
    background-color: transparent;
}
.gemsShopTab:hover {
    color: var(--ark-extended-semantic-success);
}
.gemsShopTab::before {
    background-color: var(--ark-extended-semantic-success);
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

/* width: 0 - 1024 */
@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .categoryPageTab {
        margin: 0;
    }
}

/* width: 0 - 767 */
@media (max-width: var(--ark-large-mobile-below-767)) {
    .tabItem {
        font-size: var(--ark-16-font-size);
        line-height: var(--ark-18-line-height);
        padding: var(--ark-spacing-s) var(--ark-spacing-l);
    }

    .tabItem::before {
        height: 0.125rem;
    }

    .gemsShopTab::before {
        height: 0.375rem;
    }

    .profilePageCollectionsTab {
        padding-left: .25rem;
        padding-right: .25rem;
    }
}

/* width: 768 - 1024 */
@media (min-width: var(--ark-large-mobile-768)) and (max-width: var(--ark-small-desktop-figma-1024)) {
}

/* width: 0 - 576 */
@media (max-width: var(--ark-small-mobile-576)) {
    .gamePageTab {
        width: 50%;
    }
}

.purchase {
    color: var(--ark-neutral-black-30-figma);
    font-weight: var(--ark-font-weight-bold);
    font-size: var(--ark-20-font-size);
    line-height: var(--ark-28-line-height);
    margin: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    min-width: 300px;
}

.purchase.active, .purchase:hover  {
    color: var(--ark-neutral-black-10-figma);
}

.purchase::before {
    background-color: var(--ark-neutral-black-10-figma);
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .purchase {
        min-width: 50%;
    }
}

@media (max-width: var(--ark-small-mobile-figma-600)) {
    .purchase {
        padding-left: 16px;
        padding-right: 16px;
    }
}