@import '../../../variables.css';

.container {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: var(--ark-hover-dark-blue-figma);
    color: var(--ark-neutral-white);
    padding: var(--ark-spacing-s);
    margin-bottom: var(--ark-spacing-xxl-5);
}

.imgBlock {
    min-width:72px;
    min-height:57px;
}

.textBlock{
    width: 70%;
    padding-left: var(--ark-spacing-s);
}

.title{
    font-weight: var(--ark-font-weight-regular-bolder);
    font-size: var(--ark-18-font-size);
    line-height: var(--ark-24-line-height);
    padding-bottom: var(--ark-spacing-s);
}

.content{
    font-size: var(--ark-14-font-size);
    line-height: var(--ark-20-line-height);
}

.arrow {
    color: var(--ark-primary-white-figma);
}

@media (max-width: var(--ark-small-mobile-figma-600)) {
    .container {
        width: 90%;
        margin: var(--ark-spacing-s) auto;
        padding: 0;
        border-radius: 10px;
    }
}