@import "../../../variables.css";

.container {
    border-radius: 50%;
    border: 2px solid var(--ark-main-green-figma);
    width: 6rem;
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.container:hover {
    background-color: rgba(1, 124, 85, 0.3);
    /*TODO: check hover state*/
}

.label {
    font-weight: var(--ark-font-weight-bold);
    font-size: var(--ark-16-font-size);
    line-height: var(--ark-24-line-height);
    color: var(--ark-main-green-figma);
    text-align: center;
}

@media (max-width: var(--ark-small-mobile-figma-600)) {
    .container {
        width: 5rem;
        height: 5rem;
    }
}