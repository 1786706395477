.contentWrapper {
    padding: 10px 0;
}

.contentWrapper h2 {
    padding-bottom: 10px;
}

.recaptchaIframe {
    width: 320px;
    min-height: 500px;
    border: none;
    overflow: hidden;
}

.invisibleCaptcha {
    display: none;
}
