@import "../../../../variables.css";

.borderedButton {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    background-color: var(--ark-neutral-white);
    border: 2px solid var(--ark-primary-green);
    border-radius: var(--ark-border-radius);
    padding: 0.8125rem 1rem;
    font-size: 1rem;
    width: 100%;
    cursor: pointer;
    overflow: hidden;
    will-change: transform;
}

.filled {
    background-color: var(--ark-primary-green-dark);
}

.borderedButton:hover {
    border: 2px solid var(--ark-primary-green-dark);
}

.borderedButton:not([disabled])::before {
    content: ' ';
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--ark-primary-green-dark);
    transform: translateX(-100%);
    transition: transform .384s var(--ark-ease-in-out-sine);
}

.borderedButton:not([disabled])::after {
    content: ' ';
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--ark-primary-green);
    transform: translateX(-100%);
    transition: transform .384s var(--ark-ease-in-out-sine);
}

.borderedButton:hover::before {
    transform: translateX(0);
    z-index: 1;
}

.borderedButton:hover::after {
    z-index: 0;
}

.borderedButton .caption {
    font-size: var(--ark-20-font-size);
    line-height: var(--ark-22-line-height);
    text-align: center;
    font-weight: var(--ark-font-weight-bold);
    color: var(--ark-primary-green);
    text-transform: uppercase;
    position: relative;
    z-index: 102;
    transition: color .384s var(--ark-ease-in-out-sine);
}

.filled .caption {
    color: var(--ark-neutral-white);
}

.borderedButton[disabled] {
    border-color: transparent;
    background-color: var(--ark-neutral-disabled);
    cursor: default;
}

.borderedButton[disabled] .caption {
    color: var(--ark-neutral-white);
}

.borderedButton:not([disabled]):hover .caption {
    color: var(--ark-neutral-white);
}

.btnLoader,
.btnLoader svg {
    width: 24px;
    height: 24px;
}
