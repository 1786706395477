@import '../../../variables.css';

.gameCard {
    display: flex;
    flex-direction: column;
    -webkit-user-select: none;
    /* Safari 3.1+ */
    -moz-user-select: none;
    /* Firefox 2+ */
    -ms-user-select: none;
    /* IE 10+ */
    user-select: none;
    /* Standard syntax */
    user-drag: none;
    -webkit-user-drag: none;
    text-decoration: none;
}

.gameCard:not(.detailed) .gameCardWrapper {
    display: flex;
    flex-direction: column-reverse;
}

.gameCard.detailed .gameCardWrapper {
    display: flex;
    flex-direction: row-reverse;
}

.gameCard.detailed:hover .imageContainer,
.gameCard.detailed:focus .imageContainer {
    box-shadow: 0 5px 5px var(--ark-primary-light-color);
}

.gameCard:hover .caption,
.gameCard:focus .caption {
    color: var(--ark-primary-blue-figma) !important;
}

.imageContainer {
    position: relative;
    flex-shrink: 0;
    transition: transform 0.32s var(--ark-ease-in-out-sine), box-shadow 0.64s var(--ark-ease-in-out-sine);
    box-shadow: 0 0 10px transparent;
    border-radius: var(--ark-border-radius);
    overflow: hidden;
    background-color: rgba(242, 242, 242, 1);
}

.imageOverlay {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.image {
    background-color: var(--ark-gray-extra-light-color);
    border-radius: var(--ark-border-radius);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 8;
    height: 100%;
    width: auto;
}

.gameCard.long.allGamesTile .imageContainer,
.gameCard.long.allGamesTile .image {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.cardPressed .imageContainer {
    transform: translateY(-5px);
}

.hiddenDescription {
    position: absolute;
    bottom: -11rem;
    left: 0;
    right: 0;
    max-height: 11rem;
    transition: bottom 0.664s var(--ark-ease-in-out-sine);
    background-color: var(--ark-main-nataline-blue-figma);
    overflow: hidden;
    box-sizing: border-box;
    border-bottom-left-radius: var(--ark-border-radius);
    border-bottom-right-radius: var(--ark-border-radius);
    font-size: var(--ark-14-font-size);
    line-height: var(--ark-16-line-height);
    font-weight: var(--ark-font-weight-regular);
    color: var(--ark-neutral-white);
    z-index: 12;
}

.hiddenDescriptionWrapper {
    padding: 10px 16px;
    margin: 0;
}

.hiddenDescriptionWrapper.additionalPadding {
    padding-bottom: 50px;
}

.hiddenDescriptionGameName {
    font-weight: var(--ark-font-weight-bold);
}

.hiddenDescriptionContent {
    padding-top: 10px;
}

.gameCard.long .image:not(.allGamesTileBg) {
    max-width: 32rem;
}

.gameCard.long .image.allGamesTileBg {
    background-image: linear-gradient(221deg, #def9ff, #94e69c);
}

.allGamesTileBgImage {
    position: absolute;
    left: 1.6875rem;
    top: 0.5rem;
    width: 19rem;
    z-index: 9;
}

.allGamesButton {
    position: absolute !important;
    left: 3.5rem;
    width: 18rem !important;
    bottom: 1.5rem;
    z-index: 10;
}

.gameCard.long .image {
    box-shadow: 0 10px 20px var(--ark-black-15-color);
}

.caption {
    margin: 0.25rem 0 0;
    font-size: var(--ark-22-font-size);
    line-height: var(--ark-h3-line-height);
    font-weight: var(--ark-font-weight-bold);
    color: var(--ark-black-color);
    transition: color 0.64s linear;
}

.info {
    flex-shrink: 0;
    flex-grow: 1;
}

.description {
    font-size: var(--ark-16-font-size);
    line-height: var(--ark-22-line-height);
    font-weight: var(--ark-font-weight-regular);
    color: var(--ark-gray-dark-color);
    margin: 0;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.tags {
    font-size: var(--ark-14-font-size);
    line-height: var(--ark-label1-line-height);
    font-weight: var(--ark-font-weight-regular);
    color: var(--ark-gray-dark-color);
    margin: 0;
    margin-top: 0.125rem;
}

.gameCard.small .caption {
    font-size: var(--ark-18-font-size);
    line-height: var(--ark-22-line-height);
}

.gameCard.detailed {
    flex-direction: row;
}

.gameCard.detailed {
    width: 21rem;
}

.gameCard.detailed .info {
    width: 12.5rem;
    margin-left: 1rem;
}

.gameCard.detailed .caption {
    color: var(--ark-black-color);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
}

.gameCard.square,
.gameCard.square .imageOverlay,
.gameCard.square .imageContainer,
.gameCard.square .image,
.gameCard.small,
.gameCard.small .imageOverlay,
.gameCard.small .imageContainer,
.gameCard.small .image {
    min-width: 7.5rem;
    min-height: 7.5rem;
    max-width: 7.5rem;
}

.gameCard.square .image {
    object-fit: cover;
}

.gameCard.square,
.gameCard.small {
    width: 7.5rem;
}

.gameCard.medium,
.gameCard.medium .imageOverlay,
.gameCard.medium .imageContainer,
.gameCard.medium .image {
    min-width: 12.875rem;
    min-height: 12.875rem;
    max-width: 12.875rem;
}

.gameCard.medium {
    width: 12.875rem;
}

.gameCard.mediumPlus,
.gameCard.mediumPlus .imageOverlay,
.gameCard.mediumPlus .imageContainer,
.gameCard.mediumPlus .image {
    min-width: 14.375rem;
    min-height: 14.375rem;
    max-width: 14.375rem;
}

.gameCard.mediumPlus {
    width: 14.375rem;
}

[data-variations='C'] .gameCard.large,
[data-variations='C'] .gameCard.large .imageOverlay,
[data-variations='C'] .gameCard.large .imageContainer,
[data-variations='C'] .gameCard.large .image {
    min-width: 338.9px;
    min-height: 338.9px;
    max-width: 338.9px;
}

.gameCard.large,
.gameCard.large .imageOverlay,
.gameCard.large .imageContainer,
.gameCard.large .image {
    min-width: 17.5rem;
    min-height: 17.5rem;
    max-width: 17.5rem;
}

.gameCard.large {
    width: 17.5rem;
}

.gameCard.large .image {
    width: 30rem;
}

.gameCard.superLarge,
.gameCard.superLarge .imageOverlay,
.gameCard.superLarge .imageContainer,
.gameCard.superLarge .image {
    min-width: 17.5rem;
    min-height: 37.5rem;
}

.gameCard.superLarge {
    width: 17.5rem;
}

/* test */
.gameCard.long,
.gameCard.long .imageOverlay,
.gameCard.long .imageContainer,
.gameCard.long .image {
    min-width: 26rem;
    min-height: 13.5rem;
    max-width: 26rem;
}

.gameCard.long {
    width: 26rem;
}

.gameCard.detailed .imageOverlay,
.gameCard.detailed .imageContainer,
.gameCard.detailed .image {
    width: 7.5rem;
    height: 7.5rem;
}

.gameCard.small,
.gameCard.medium,
.gameCard.large,
.gameCard.superLarge {
    height: auto;
    max-width: none;
}

.skeletonImage {
    width: 14.375rem;
    height: 14.375rem;
    border-radius: 1.25rem;
    background-color: var(--ark-gray-light2-color);
}

.skeletonText {
    width: 80%;
    height: 24px;
    margin-top: 0.5rem;
    background-color: var(--ark-gray-light2-color);
}

.limitCharacters {
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.favoriteBadge {
    position: absolute;
    top: calc(6 / 120 * 100%); /* 5% */
    right: calc(6 / 120 * 100%); /* 5% */
    width: calc(28 / 120 * 100%); /* 23% */
    height: calc(28 / 120 * 100%); /* 23% */
    max-width: 2.25rem; /* 28px / 16px as 1rem */
    max-height: 2.25rem;
    background-image: url('./icons/favorites/heart.svg');
    background-size: calc(16 / 28 * 100%) calc(16 / 28 * 100%); /* 57% */
    background-position: center;
    background-repeat: no-repeat;
    background-color: #d50032;
    border-radius: 50%;
    border: 1px solid #f9f9f9;
    z-index: 8;
}

.gameCard.superLarge .favoriteBadge {
    max-width: 4.08rem;
    max-height: 4.08rem;
}

@media (max-width: var(--ark-small-mobile-below-575)) {
    .skeletonImage {
        width: 6.5rem;
        height: 6.5rem;
    }

    .skeletonText {
        height: 1rem;
    }
}

@media (max-width: var(--ark-small-mobile-576)) {
    .gameCard.detailed {
        width: 17rem;
    }

    .gameCard.detailed .info {
        width: 8.5rem;
        margin-left: 1rem;
    }
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .skeletonImage {
        width: 10.25rem;
        height: 10.25rem;
    }

    .skeletonText {
        height: 1.125rem;
    }

    .cardPressed:not(.detailed) .imageContainer {
        transform: translateY(0);
    }

    .cardPressed:not(.detailed) {
        transform: translateY(-5px);
    }

    .hiddenDescription {
        transform: scale(0);
    }

    .gameCard.long.allGamesTile {
        display: none;
    }

    .gameCard.large,
    .gameCard.large .imageOverlay,
    .gameCard.large .imageContainer,
    .gameCard.large .image {
        max-height: 14.75rem;
    }

    .gameCard.large .image {
        width: 30rem;
    }

    .gameCard.medium,
    .gameCard.medium .imageOverlay,
    .gameCard.medium .imageContainer,
    .gameCard.medium .image {
        max-width: 10.25rem;
        min-width: 10.25rem;
        max-height: 10.25rem;
        min-height: 10.25rem;
    }

    .gameCard.medium {
        width: 10.25rem;
    }

    .gameCard.medium,
    .gameCard.large {
        height: auto;
        max-height: none;
    }

    .gameCard.detailed .imageOverlay,
    .gameCard.detailed .imageContainer,
    .gameCard.detailed .image {
        height: 6.125rem;
        width: 6.125rem;
    }

    /* test */
    .gameCard.long,
    .gameCard.long .imageOverlay,
    .gameCard.long .imageContainer,
    .gameCard.long .image {
        /* max-width: 15.3125rem;
        max-height: 8.625rem; */

        max-width: 15.3125rem;
        max-height: 8.625rem;
        min-width: 15.3125rem;
        min-height: 9.125rem;
    }

    .gameCard.long {
        width: 15.3125rem;
    }

    .gameCard.long .image {
        width: auto;
    }

    .caption {
        font-size: var(--ark-20-font-size);
        line-height: var(--ark-32-line-height);
    }

    .tags,
    .description {
        font-size: var(--ark-12-font-size);
        line-height: var(--ark-14-line-height);
    }

    .description {
        margin-top: 0.375rem;
        margin-bottom: 0.25rem;
    }

    .gameCard.small,
    .gameCard.medium,
    .gameCard.large,
    .gameCard.long,
    .gameCard.superLarge {
        height: auto;
        max-width: none;
    }

    .gameCard.detailed {
        width: 19rem;
    }

    .gameCard.detailed .info {
        width: 10.5rem;
        margin-left: 1rem;
    }

    [data-variations='C'] .gameCard.small,
    [data-variations='C'] .gameCard.small .imageOverlay,
    [data-variations='C'] .gameCard.small .imageContainer,
    [data-variations='C'] .gameCard.small .image {
        min-width: 9.75rem;
        min-height: 9.75rem;
        max-width: 9.75rem;
    }

    .gameCard.small,
    .gameCard.small .imageOverlay,
    .gameCard.small .imageContainer,
    .gameCard.small .image {
        min-width: 6.5rem;
        min-height: 6.5rem;
        max-width: 6.5rem;
    }

    .gameCard.small {
        width: 6.5rem;
    }
}

@media (max-width: var(--ark-medium-desktop-below-1199)) {
    .skeletonImage {
        width: 12.875rem;
        height: 12.875rem;
    }
}
