@import "../../../../variables.css";

.tabContainer {
    height: 100%;
}

.gemsTabDarkBackground {
    background-color: var(--ark-primary-royal-blue-figma);
}

.advantageTabDarkBackground {
    background-color: var(--ark-advantage-blue-figma);
}