@import "../../../variables.css";

.container {
    width: 4.375rem;
    height: 1.875rem;
    background: var(--ark-primary-royal-blue-figma);
    border: 1px solid var(--ark-primary-white-figma);
    box-sizing: border-box;
    border-radius: 2.5rem;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
}

.container:hover {
    background: var(--ark-primary-blue-figma);
}

.langLabel {
    font-size: var(--ark-18-font-size);
    font-weight: var(--ark-font-weight-bold);
    line-height: var(--ark-14-line-height);
    color: var(--ark-primary-white-figma);
    display: flex;
    align-items: center;
    text-transform: uppercase;
    position: absolute;
    left: 0.625rem;
    top: calc(50% - 14px/2);
    transition: all .3s ease-in-out;
}

.flagCircle {
    width: 1.375rem;
    height: 1.375rem;
    border-radius: 1.875rem;
    position: absolute;
    right: 0.188rem;
    top: 0.188rem;
    transition: all .3s ease-in-out;
    overflow: hidden;
}

.flagCircle svg {
    width: 1.375rem;
    height: 1.375rem;
}

.container.switched .langLabel {
    left: 2.313rem;
    top: calc(50% - 0.875rem/2);
}

.container.switched .flagCircle {
    right: 2.75rem;
    top: 0.188rem;
}

.container.disabled {
    background-color: var(--ark-secondary-solid-midgrey);
    cursor: auto;
}

.container.disabled .langLabel {
    color: var(--ark-primary-asbestos-figma);
}

.container.disabled .flagCircle .flagIcon {
    filter: grayscale(1);
}

@media (max-width: var(--ark-medium-mobile-figma-767)) {
    .container {
        width: 90px;
        height: 48px;
    }

    .flagCircle {
        width: 2.375rem;
        height: 2.375rem;
        right: 0.188rem;
        top: 4px;
    }

    .flagCircle svg {
        width: 2.375rem;
        height: 2.375rem;
    }

    .container.switched .langLabel {
        left: 3.313rem;
    }

    .container.switched .flagCircle {
        right: 47px;
        top: 4px;
    }


}