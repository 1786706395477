@import "../../../variables.css";

/* MOBILE FIRST */

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    width: 100%;
    border-radius: .5rem;
    background-image:
        url(./images/gems/GemsImageBiggerLeft.svg),
        url(./images/gems/GemsImageSmallerRight.svg),
        linear-gradient(314.74deg, #C28C2B 12.84%, #E6C14E 48.8%, #C28C2B 84.98%)
    ;
    background-size: 111px 160px, 104px 158px, 100% 100%;
    background-position: -53px -41px, calc(100% + 49px) -40px, center;
    background-repeat: no-repeat, no-repeat, no-repeat;
    padding: 1.3rem 12.5% var(--ark-16-font-size) 12.5%;
    margin-bottom: var(--ark-spacing-l);
}

.container.__forSubscribers {
    padding-left: var(--ark-20-font-size);
    padding-right: var(--ark-20-font-size);
}

.logo {
    height: 3.2rem;
    width: fit-content;
    user-select: none;
    pointer-events: none;
}

.button {
    width: 100%;
    padding: .5rem 3rem;
    color: #E3BD4B!important;
    font-size: var(--ark-16-font-size);
    font-weight: var(--ark-font-weight-bold);
    line-height: var(--ark-24-line-height);
    vertical-align: middle;
    white-space: nowrap;
}

.subscriberText, .list_item_text {
    color: #060064;
    font-size: var(--ark-16-font-size);
    line-height: 1.3rem;
    vertical-align: middle;
    font-weight: var(--ark-font-weight-regular);
}

.subscriberText {
    width: 100%;
    font-weight: var(--ark-font-weight-bold);
    margin-top: var(--ark-18-font-size);
    position: relative;
    text-align: center;
}

.list {
    margin-top: var(--ark-16-font-size);
    margin-bottom: .6rem;
    list-style: none;
}

.list, .list_item {
    display: inline-block;
    width: 100%;
    min-width: fit-content;
    line-height: var(--ark-20-font-size);
}

.list_item {
    white-space: nowrap;
    overflow: hidden;
    padding-left: 1.4rem;
    position: relative;
}
.list_item:before, .subscriberText:before {
    content: url("/images/gems/GemsAaPromoListBullet.svg");
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    width: var(--ark-16-font-size);
    height: var(--ark-16-font-size);
}

.subscriberText:before {
    display: none;
}

.list_item_title {
    color: #44408B;
    font-weight: var(--ark-font-weight-regular-bolder);
}

/* MOBILE-TABLET */

@media (min-width: 470px) {
    .container {
        padding-left: calc(22.5%);
    }
    .logo {
        position: relative;
        left: -5%;
    }
}

/* TABLET */

@media (min-width: 769px) {
    .container {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-left: 3.25rem;
        padding-right: 3.64rem;
        padding-top: 1.4rem;
        padding-bottom: 1.4rem;
        background-image:
                url(./images/gems/GemsImageBiggerLeft.svg),
                url(./images/gems/GemsImageBiggerLeft.svg),
                linear-gradient(314.74deg, #C28C2B 12.84%, #E6C14E 48.8%, #C28C2B 84.98%)
    ;
        background-size: auto 100%, auto 100%, 100% 100%;
        background-position: -5rem center, calc(100% + 5rem) center, center;
    }
    .__forSubscribers {
        justify-content: center;
    }
    .logo {
        left: 0;
        margin-right: var(--ark-30-font-size);
    }
    .list {
        width: auto;
        min-width: auto;
    }
    .list_item, .subscriberText {
        white-space: initial;
        font-size: var(--ark-20-font-size);
    }
    .list, .subscriberText, .button {
        margin-top: 0;
        margin-bottom: 0;
    }
    .logo, .button {
        max-width: fit-content;
    }
    .button {
        margin-left: 2.85rem;
        font-size: var(--ark-20-font-size);
        padding-left: var(--ark-spacing-l);
        padding-right: var(--ark-spacing-l);
    }
    .subscriberText {
        text-align: left;
        padding-left: 2rem;
        max-width: 50%;
    }
    .subscriberText:before {
        display: block;
    }
}

/* DESKTOP */

@media (min-width: 1025px) {
    .container {
        flex-direction: row;
        padding-left: 4.25rem;
        padding-right: 4.9rem;
        background-size: auto 105%, auto 120%, 100% 100%;
        background-position: -5.1rem center, calc(100% + 6rem) 20%, center;
    }

    .logo {
        height: 4.6rem;
    }

    .list_item, .subscriberText {
        font-size: var(--ark-16-font-size);
    }

    .subscriberText {
        padding-left: 0;
        font-weight: var(--ark-font-weight-bold);
    }

    .subscriberText:before {
        display: none;
    }
}
