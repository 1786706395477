@import '../../../variables.css';

:root {
    --bottom-row-item-line-height: 1.49;
}

.footer {
    position: relative;
    background-color: var(--ark-main-nataline-blue-figma);
    padding-left: var(--ark-spacing-l);
    padding-right: 1.5rem;
    padding-bottom: 0.5rem;
    height: auto;
}

.footerWrapper {
    padding-top: 2.5rem;
    grid-column-gap: 1.5rem;
    min-height: 20.625rem;
    display: grid;
    grid-template-areas:
        '. arkLogo arkLogo . . topCategoryDropdown topCategoryDropdown topCategoryDropdown . langSwitcher langSwitcher langSwitcher'
        '. socialLinks socialLinks . . supports supports supports . corporate corporate .'
        '. . . . . . . . . . . madeInNY'
        'legalBlock legalBlock legalBlock legalBlock legalBlock legalBlock legalBlock . . . footerYearItem footerYearItem';
    grid-template-columns: repeat(12, 1fr);
}

.langSwitcher {
    grid-area: langSwitcher;
}

.topCategoryDropdown {
    grid-area: topCategoryDropdown;
}

.supportsBlock {
    grid-area: supports;
}

.corporateBlock {
    grid-area: corporate;
}

.legalBlock {
    grid-area: legalBlock;
    align-self: center;
}

.footerList {
    list-style: none;
}

.footerListItem,
.footerListItem a {
    text-transform: capitalize;
    font-weight: var(--ark-font-weight-regular);
    font-size: var(--ark-20-font-size);
    padding-top: 0;
    line-height: var(--ark-28-line-height);
    color: var(--ark-neutral-white);
    text-decoration: none;
    transition: color 0.166s linear;
    min-height: var(--ark-minSize-for-interactive-el);
    display: flex;
    align-items: center;
}

.noCapitalization {
    text-transform: none !important;
}

.footerListItem.animatedHover a:hover,
.footerListItem.animatedHover button:hover {
    color: var(--ark-primary-light-color) !important;
}

.footerListItem button {
    background: none;
    box-shadow: none;
    border: 0;
    text-align: left;
    line-height: var(--ark-28-line-height);
    padding: 0;
    text-transform: none;
    color: var(--ark-neutral-white);
}

.legalList {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.legalList li {
    display: inline-block;
    margin-right: 1rem;
    padding-bottom: 0;
    margin-left: 1rem;
    display: flex;
}

.legalList li button,
.legalList li a {
    font-size: var(--ark-16-font-size) !important;
    color: var(--ark-neutral-white-60) !important;
    display: flex;
    align-items: center;
}

.hidden {
    opacity: 0;
}

/************************/

.socialLinks {
    display: flex;
    align-items: end;
    width: 13rem;
    height: 5rem;
    grid-area: socialLinks;
    padding-left: 1.25rem;
}

.socialLink {
    padding-right: 1.5rem;
}

.socialLink a {
    display: flex;
    background-color: var(--ark-primary-white-figma);
    width: 2.5rem;
    height: 2.5rem;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: background-color 0.531s var(--ark-ease-in-out-sine);
}

.socialLink a:hover {
    background-color: var(--ark-hover-light-natalie-blue-figma);
}

.footerSocialIcon {
    color: var(--ark-primary-black-figma);
}

.footerYearItem {
    grid-area: footerYearItem;
    font-size: var(--ark-18-font-size);
    font-weight: var(--ark-font-weight-regular);
    color: var(--ark-neutral-white);
    line-height: 2rem;
    height: 100%;
    display: flex;
    justify-content: end;
    align-items: flex-end;
    padding-bottom: .5rem;
}

.madeInNY {
    position: relative;
    grid-area: madeInNY;
    width: 3.75rem;
    height: 3.75rem;
    margin-top: -4rem;
}

.logoLink {
    color: inherit;
}

.dropdownCaption {
    padding: 0.6rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(196, 196, 196, 0.4);
    border: none;
    border-radius: 0.625rem;
}

.dropdownCaption .dropdownCaptionText {
    font-size: var(--ark-28-font-size);
    line-height: var(--ark-40-line-height);
    color: var(--ark-white-50-color);
    text-transform: capitalize;
}

.dropdown {
    display: none;
}

.languageSwitcherItem {
    padding-bottom: 0.6rem;
    /* for ENG btn Desktop */
}

.dropDownItem {
    padding-bottom: 0;
    /* for ENG btn Desktop */
}

.arkLogo {
    color: var(--ark-neutral-white);
    display: flex;
    width: 11.25rem;
    height: 3rem;
    align-items: center;
    grid-area: arkLogo;
    padding-left: 1.25rem;
}

@media (min-width: var(--ark-small-desktop-1025)) and (max-width: var(--ark-large-desktop-figma-1440)) {
    .footerWrapper {
        grid-template-areas:
            '. arkLogo arkLogo . . topCategoryDropdown topCategoryDropdown topCategoryDropdown . langSwitcher langSwitcher langSwitcher'
            '. socialLinks socialLinks . . supports supports supports . corporate corporate .'
            '. . . . . . . . . . . madeInNY'
            'legalBlock legalBlock legalBlock legalBlock legalBlock legalBlock legalBlock legalBlock legalBlock footerYearItem footerYearItem footerYearItem';
    }
}

/* 767 < x < 1024 */
@media (min-width: var(--ark-medium-mobile-figma-767)) and (max-width: var(--ark-small-desktop-figma-1024)) {
    .footerWrapper {
        grid-template-areas:
            'arkLogo arkLogo arkLogo topCategoryDropdown topCategoryDropdown topCategoryDropdown langSwitcher langSwitcher'
            'socialLinks socialLinks socialLinks supports supports supports corporate corporate '
            '. . . . . . . madeInNY '
            'legalBlock legalBlock legalBlock legalBlock legalBlock legalBlock footerYearItem footerYearItem';
        grid-template-columns: repeat(8, 1fr);
    }

    .legalBlock {
        padding-top: 3rem;
    }

    .legalList {
        justify-content: start;
    }

    .legalList li {
        margin-right: 0;
    }

    .legalList li button,
    .legalList li a {
        font-size: var(--ark-14-font-size) !important;
    }

    .footerYearItem {
        font-size: var(--ark-14-font-size);
        line-height: 3rem;
        align-items: end;
    }

    .dropDownItem {
        padding-bottom: 0.8rem;
    }

    .madeInNY {
        margin-top: -3rem;
    }

}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .footer {
        height: 100%;
    }
}

/* 600 < x < 767 */
@media (min-width: var(--ark-small-mobile-figma-600)) and (max-width: var(--ark-medium-mobile-figma-767)) {
    .footerWrapper {
        padding-top: 1rem;
        padding-bottom: 1rem;
        grid-template-rows: auto;
        gap: 1rem;
        grid-template-columns: repeat(8, 1fr);
        grid-template-areas:
            'arkLogo arkLogo arkLogo arkLogo socialLinks socialLinks socialLinks socialLinks'
            'topCategoryDropdown topCategoryDropdown topCategoryDropdown topCategoryDropdown langSwitcher langSwitcher langSwitcher langSwitcher'
            'supports supports supports supports legalBlock legalBlock legalBlock legalBlock'
            'corporate corporate corporate corporate corporate corporate corporate corporate'
            'madeInNY madeInNY madeInNY madeInNY footerYearItem footerYearItem footerYearItem footerYearItem';
    }

    .dropDownItem {
        padding-left: 1.25rem;
    }

    .corporateBlock {
        padding-left: 1.25rem;
        margin-top: -7rem;
    }

    .madeInNY {
        padding-left: 1.25rem;
        margin-top: 0;
    }

    .arkLogo {
        margin-top: var(--ark-spacing-s);
    }

    .socialLinks {
        margin-top: var(--ark-spacing-s);
        align-items: center;
        padding-left: 0;
    }

    .supportsBlock {
        padding-top: 0;
        padding-left: 1.25rem;
    }

    .corporateBlock {
        padding-top: 0;
    }

    .legalBlock {
        padding-left: 0;
    }

    .legalList {
        justify-content: start;
        display: block;
    }

    .legalList li {
        display: block;
        padding-bottom: var(--ark-spacing-s);
        margin-left: 0;
    }

    .legalList li button,
    .legalList li a {
        font-size: var(--ark-20-font-size) !important;
        color: var(--ark-neutral-white-60) !important;
        min-height: auto;
    }

    .madeInNY {
        margin-left: 0;
        margin-top: -1rem;
    }

    .footerYearItem {
        font-size: var(--ark-20-font-size);
        align-items: end;
        justify-content: start;
    }
}

@media (max-width: var(--ark-small-mobile-figma-below-599)) {
    .footerWrapper {
        grid-template-areas:
            'arkLogo arkLogo arkLogo arkLogo'
            'socialLinks socialLinks socialLinks socialLinks'
            'langSwitcher langSwitcher langSwitcher langSwitcher'
            'topCategoryDropdown topCategoryDropdown topCategoryDropdown topCategoryDropdown'
            'supports supports supports supports'
            'corporate corporate corporate corporate'
            'legalBlock legalBlock legalBlock legalBlock'
            'footerYearItem footerYearItem footerYearItem madeInNY';
        grid-template-columns: repeat(4, 1fr);
        gap: 0;
        padding-top: 1.5rem;
        padding-bottom: 1rem;
    }

    .corporateBlock {
        padding-top: 0;
    }

    .footer.withStickyBtn {
        padding-bottom: calc(5rem + 70px);
        /* 70px - height of sticky btn, 5rem for mobilenavmenu */
    }

    .madeInNY {
        margin-top: 0;
    }

    .legalBlock {
        padding-left: 0;
    }

    .legalList li {
        display: block;
        padding-right: 0;
        padding-bottom: var(--ark-spacing-s);
    }

    .legalList li button,
    .legalList li a {
        font-size: var(--ark-20-font-size) !important;
    }

    .linksBlock {
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .supportsBlock {
        padding-top: 0;
    }

    .arkLogo {
        justify-content: center;
        padding-left: 0;
        justify-self: center;
        margin-top: 0;
    }

    .socialLinks {
        justify-content: space-around;
        justify-self: center;
        padding-left: 0;
        align-items: center;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .socialLink {
        padding: 0;
    }

    .supportsBlock {
        margin-top: 0.875rem;
    }

    .corporateBlock {
        margin-top: 0;
    }

    .langSwitcher {
        margin: 0 auto;
    }

    .languageSwitcherItem {
        margin-left: -0.7rem;
        margin-right: -0.7rem;
        padding-bottom: 1.5rem;
    }

    .footerList {
        padding-left: 0;
        display: block;
        flex-wrap: inherit;
        justify-content: inherit;
    }

    .legalList li {
        padding-bottom: 0;
        margin-left: 0;
    }

    .legalList li a {
        align-items: baseline;
    }

    .footerYearItem {
        font-size: var(--ark-20-font-size);
        align-items: end;
        justify-content: start;
    }

    .topCategoryDropdown {
        margin: 0;
        padding-top: 1.5rem;
        padding-bottom: 0;
    }

}

/**** Check this Logic***/
@media (max-width: var(--ark-small-desktop-figma-1024)) and (orientation: portrait) {
    .footerGamepage {
        padding-bottom: 9rem !important;
    }
}

@media (max-width: var(--ark-small-desktop-figma-1024)) and (orientation: portrait) {
    .footer {
        height: auto;
        padding-bottom: 5rem;
    }
}

@media (min-width: 768px) and (max-width: 1380px) {
    .madeInNY {
        transform: translate(-33%, calc(3.75rem - .5rem));
    }
}
