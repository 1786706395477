@import '../../../variables.css';

.container {
    display: flex;
    width: 100%;
    height: 100%;
    min-height: inherit;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.text {
    margin-top: 10px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
}

.spinner {
    width: 42px;
    height: 42px;
    animation: rotator 1.4s linear infinite;
    will-change: transform, animation;
}
.fullPageLoader{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.85;
    background: var(--ark-primary-white-figma);
    z-index: 10;
}

.inWrapper{
    z-index: 10;
    opacity: 0.85;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: transparent;
}

@keyframes rotator {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(270deg); }
}

.path {
    stroke-width: 6px;
    stroke-linecap: round;
    stroke-dasharray: 187;
    stroke-dashoffset: 0;
    transform-origin: center;
    will-change: transform, animation;
    animation:
            dash 1.4s ease-in-out infinite,
            colors 5.6s ease-in-out infinite;
}

@keyframes colors {
    0% { stroke: #08B238; }
    25% { stroke: #9AEFB0; }
    50% { stroke: #08B238; }
    75% { stroke: #9AEFB0; }
    100% { stroke: #08B238; }
}

@keyframes dash {
    0% { stroke-dashoffset: 187px; }
    50% {
        stroke-dashoffset: 46.75px;
        transform:rotate(135deg);
    }
    100% {
        stroke-dashoffset: 187px;
        transform:rotate(450deg);
    }
}
