@import "../../../../../../../../variables.css";

.container {
    font-size: var(--ark-20-font-size);
    line-height: var(--ark-22-line-height);
    color: var(--ark-primary-white-figma);
    text-align: center;
    padding-top: 17px;
}

.link {
    color: #12B76A;
    text-decoration: underline;
}

/* 1359 */
@media (max-width: var(--ark-medium-desktop-figma-below-1359)) {
    .container {
        font-size: var(--ark-16-font-size);
        line-height: var(--ark-22-line-height);
    }
}


/* - 1023 */
@media (max-width: var(--ark-small-desktop-figma-below-1023)) {
    .container {
        font-size: var(--ark-16-font-size);
        line-height: var(--ark-22-line-height);
        padding-top: 8px;
    }
}

/* - 719 */
@media (max-width: var(--ark-medium-mobile-figma-below-719)) {
    .container {
        font-size: var(--ark-14-font-size);
        line-height: var(--ark-16-line-height);
    }
}
