@import url('../../../variables.css');

.facebookButton {
    margin-right: 1rem;
}

.googleButton {
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .facebookButton {
        margin-right: 0;
    }
}
