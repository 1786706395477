@import '../../../variables.css';

.notifyMessage {
    background-color: var(--ark-extended-solid-blue);
    padding: 20px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    border-radius: 10px;
    align-items: center;
    margin-bottom: 1.5rem;
}

.message {
    font-weight: var(--ark-font-weight-bold);
    font-size: var(--ark-18-font-size);
    line-height: var(--ark-22-line-height);
    color: var(--ark-primary-white-figma);
}

@media (max-width: var(--ark-small-mobile-figma-below-599)) {
    .notifyMessage {
        padding: 10px 20px;
    }
}
