@import "../../../variables.css";
.container {
    background: #F9F9F9;
    border-radius: 10px;
    width: 288px;
    height: 244px;
    display: flex;
    padding-top: 20px;
    flex-direction: column;
    position: relative;
    z-index: 1;
}

.container:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 10px;
    border: 4px solid rgba(194, 140, 43, 1);
    z-index: -1;
}

.container:hover {
    box-shadow: 0px 2px 40px rgba(214, 170, 63, 0.7);
}

.info {
    padding-left: 24px;
}

.heading {
    color: var(--ark-main-green-figma);
    font-weight: bold;
    font-size: var(--ark-28-font-size);
    margin-bottom: 16px;
}

.price {
    font-size: var(--ark-28-font-size);
    color: var(--ark-primary-black-figma);
    font-weight: bold;
}

.monthLabel {
    font-size: var(--ark-18-font-size);
    color: var(--ark-primary-black-figma);
}

.saveStar {
    position: absolute;
    top: -5px;
    right: -8px;
}

.btnRow {
    display: flex;
    justify-content: center;
    margin-top: 24px;
}

.savingsInfo {
    font-size: var(--ark-16-font-size);
    color: var(--ark-main-green-figma);
    text-align: center;
    margin-top: 16px;
    line-height: var(--ark-20-line-height);
}

.btnSubscribe {
    padding: 10px 20px;
}


/*******managePlanStyles ********/

.container.managePlanStyles {
    display: grid;
    width: 640px;
    height: 150px;
    padding-bottom: 16px;
    grid-template-areas: "saveStar info btnRow" "saveStar info btnRow" "saveStar savingsInfo btnRow"
}

.container.managePlanStyles .info {
    grid-area: info;
    padding-left: 0;
}

.container.managePlanStyles .info .heading {
    margin-bottom: 8px;
}

.container.managePlanStyles .savingsInfo {
    grid-area: savingsInfo;
    text-align: left;
}

.container.managePlanStyles .btnRow {
    grid-area: btnRow;
    margin-top: 0;
    align-self: center;
    justify-content: start;
}

.container.managePlanStyles .saveStar {
    grid-area: saveStar;
    position: relative;
}

.container.managePlanStyles .btnRow,
.btnSubscribe {
    min-width: 150px;
}

.container.managePlanStyles.managePlanUpdate {
    width: 486px;
    grid-template-areas: "saveStar info" "saveStar savingsInfo"
}


/*****Media *******/

@media (max-width: var(--ark-small-mobile-figma-below-599)) {
    .heading {
        font-size: var(--ark-24-font-size);
    }
    .price {
        font-size: var(--ark-24-font-size);
    }
    .container.managePlanStyles,
    .container.managePlanStyles.managePlanUpdate {
        display: grid;
        width: 288px;
        height: inherit;
        padding-bottom: 24px;
        grid-template-areas: " info saveStar" " info saveStar" " btnRow btnRow" " savingsInfo savingsInfo"
    }
    .container.managePlanStyles .btnRow {
        justify-self: center;
        margin-top: 24px;
    }
    .container.managePlanStyles .info {
        padding-left: 16px;
    }
    .container.managePlanStyles .saveStar {
        position: absolute;
        top: -25px;
    }
    .container.managePlanStyles .savingsInfo {
        text-align: center;
        margin-top: 22px;
    }
    .savingsInfo {
        width: 100%;
        align-items: center;
        display: flex;
        justify-content: center;
    }
    .savingsInfo div {
        width: 168px;
    }
}