@import '../../../variables.css';

.loggedInView {
    padding: var(--ark-spacing-l) var(--ark-spacing-xxl) var(--ark-spacing-xxl-7);
    background-color: inherit;
}

.notLoggedInView {
    background-color: var(--ark-neutral-white);
}

/* width: 0 - 599 */
@media (max-width: var(--ark-small-mobile-figma-below-599)) {
    .loggedInView {
        padding: var(--ark-spacing-s) var(--ark-spacing-s) var(--ark-spacing-xxl-7);
    }
}
