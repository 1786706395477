@import '../../../variables.css';

.leaderboardTabpanel {
    height: 100%;
}

.leaderboardContainer {
    display: flex;
    flex-direction: row;
    height: 100%;
    background-color: var(--ark-neutral-white-80);
}

.leaderboardContent {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
}

.emptyDataPanelContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--ark-neutral-white);
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.emptyDataPanelTextContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1.5rem;
    font-size: var(--ark-24-font-size);
    line-height: var(--ark-32-line-height);
    font-weight: var(--ark-font-weight-regular);
    color: var(--ark-neutral-black);
    max-width: 25.625rem;
}

.emptyDataPanelText {
    text-align: center;
}

.emptyDataPanelAsset {
    max-height: 19.75rem;
    height: 100%;
    width: auto;
    object-fit: contain;
}

.leaderboardHeader {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: 'day week month';
    background-color: var(--ark-primary-blue-figma);
    cursor: pointer;
}

.dayTab {
    grid-area: day;
}

.weekTab {
    grid-area: week;
}

.monthTab {
    grid-area: month;
}

.dayTab,
.weekTab,
.monthTab {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3.75rem;
    background-color: var(--ark-primary-blue-figma);
    color: var(--ark-neutral-white);
    font-size: var(--ark-26-font-size);
    font-weight: var(--ark-font-weight-regular);
    line-height: var(--ark-32-line-height);
    transition: 0.3s;
}

.dayTab:not(.activeTab):hover,
.weekTab:not(.activeTab):hover,
.monthTab:not(.activeTab):hover {
    background-color: var(--ark-highlights-blue-light-10);
    color: var(--ark-secondary-blue-light);
}

.activeTab {
    background-color: var(--ark-secondary-blue-light-20);
    font-weight: var(--ark-font-weight-bold);
    outline-offset: calc(var(--focus-state-outline-width) * -1);
}

.userBottomBanner,
.userTopBanner {
    position: absolute;
    left: 0;
    z-index: 2;
    display: none;
    cursor: pointer;
}

.userTopBanner {
    top: 3.75rem;
}

.userBottomBanner {
    bottom: 0;
}

.leaderboardScrollableBody {
    overflow: auto;
    height: 100%;
}

.userBottomNotLoggedInBanner {
    z-index: 1;
    box-shadow: 0 0 8px 0 var(--ark-primary-green-40);
    background-color: var(--ark-neutral-white);
}

.leaderboardRow {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    height: 4rem;
    grid-template-areas: 'details name score';
    background-color: var(--ark-neutral-white);
}

.rowStripe {
    background-color: var(--ark-neutral-light);
}

.highlightCurrent {
    background-color: var(--ark-lb-light);
    box-shadow: 0 0 8px 0 var(--ark-primary-green-40);
    z-index: 1;
    position: relative;
}

.details {
    grid-area: details;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.detailsPlaceAndCup {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.detailsPlace {
    position: relative;
    min-width: 36px;
    padding-left: 1rem;
    color: var(--ark-primary-blue-figma);
    font-weight: var(--ark-font-weight-bold);
    font-size: var(--ark-20-font-size);
}

.detailsCup {
    display: flex;
}

.detailsAvatar {
    margin-left: 10px;
    margin-right: 20px;
}

.detailsFlag {
    display: flex;
}

.countryFlag,
.countryFlagMobile {
    width: 1.75rem;
    height: 1.25rem;
}

.countryFlagMobile {
    display: none;
}

.name {
    outline-offset: calc(var(--focus-state-outline-width) * -1);
    grid-area: name;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: var(--ark-font-weight-bold);
    color: var(--ark-neutral-black);
    font-size: var(--ark-18-font-size);
    overflow: hidden;
    text-decoration: none;
}

.scoreTextContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: var(--ark-primary-blue-figma);
    font-weight: var(--ark-font-weight-regular);
    font-size: var(--ark-26-font-size);
    line-height: var(--ark-32-line-height);
    overflow: hidden;
    padding-right: 2.5rem;
}

.scoreText {
    text-align: right;
    width: auto !important;
}

.cupImage {
    height: 1.875rem;
    width: 1.875rem;
}

.rowScore {
    grid-area: score;
}

.leaderboardSignInRow {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: 'signInAvatar signInButton signInScore';
    cursor: initial;
    height: 4rem;
}

.signInAvatar,
.signInButton,
.signInScore {
    display: flex;
    justify-content: center;
    align-items: center;
}

.signInAvatar {
    grid-area: signInAvatar;
    display: none;
    padding-left: 39px;
}

.signInButton {
    grid-area: signInButton;
}

.signInScore {
    grid-area: signInScore;
    display: none;
    padding-right: 30px;
}

.loginButton {
    width: 16.5rem !important;
    height: 3rem;
    border-radius: 10px;
}

.show {
    display: block;
}

.textEllipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    cursor: pointer;
}

.gamePromoContainer {
    background-color: var(--ark-neutral-white);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    width: 100%;
    display: none;
}

.gamePromo {
    min-width: 12.813rem;
    max-width: 17.5rem;
    border-radius: 10px;
    width: 100%;
}

.notification-icon {
    position: absolute;
}
.notification-icon.nav {
    top: 10px;
    right: 10px;
}
.notification-icon.leaderboard-row {
    top: -12px;
}

.overflowFix {
    overflow: auto;
}

/* 575px and lower */
@media (max-width: var(--ark-small-mobile-below-575)) {
    .detailsPlaceAndCup {
        flex-direction: column;
    }

    .detailsPlace {
        color: var(--ark-primary-blue-figma);
        font-size: var(--ark-16-font-size);
        line-height: var(--ark-18-line-height);
        padding-left: initial;
        min-width: 1.875rem;
        text-align: center;
    }

    .detailsAvatar {
        margin-left: initial;
        margin-right: initial;
    }

    .name {
        font-size: var(--ark-14-font-size);
        line-height: var(--ark-16-line-height);
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding-top: 0.25rem;
    }

    .scoreTextContainer {
        font-weight: var(--ark-font-weight-bold);
        font-size: var(--ark-16-font-size);
        line-height: var(--ark-24-line-height);
    }

    .detailsFlag {
        display: none;
    }

    .countryFlagMobile {
        display: block;
        margin-bottom: 0.25rem;
    }
}

/* 576-767px */
@media (min-width: var(--ark-small-mobile-576)) and (max-width: var(--ark-large-mobile-below-767)) {
    .name {
        font-size: var(--ark-16-font-size);
        line-height: var(--ark-24-line-height);
    }

    .scoreTextContainer {
        font-weight: var(--ark-font-weight-bold);
        font-size: var(--ark-20-font-size);
        line-height: var(--ark-32-line-height);
    }
}

/* 767px and lower */
@media (max-width: var(--ark-large-mobile-below-767)) {
    .dayTab,
    .weekTab,
    .monthTab {
        height: 3rem;
        font-size: var(--ark-20-font-size);
    }

    .userTopBanner {
        top: 3rem;
    }

    .emptyDataPanelTextContainer {
        font-size: var(--ark-20-font-size);
        padding-left: 2rem;
        padding-right: 2rem;
        max-width: 22.25rem;
    }

    .emptyDataPanelAsset {
        max-height: 9.875rem;
    }
}

/* 1024px and lower */
@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .userBottomNotLoggedInBanner {
        box-shadow: initial;
    }
}

/* 1025px and higher */
@media (min-width: var(--ark-small-desktop-1025)) {
    .signInAvatar,
    .signInScore {
        display: flex;
    }
}

/* 1600px and higher */
@media (min-width: 1600px) {
    .leaderboardContent {
        min-width: 827px;
        max-width: 827px;
    }

    .gamePromoContainer {
        display: flex;
    }
}

/* 375px height and lower */
@media (max-height: 375px) {
    .emptyDataPanelTextContainer {
        padding-top: 0;
    }

    .emptyDataPanelContainer {
        justify-content: initial;
    }

    .emptyDataPanelAsset {
        height: 60%;
    }
}
